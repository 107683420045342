import styled from "styled-components";

export const MoreDeetsStyles = styled.div`
  width: 100%;
  padding: 30px;
  @media (max-width: 1000px) and (min-width: 900px) {
    padding: 30px 0 0 10px;
  }
  @media (max-width: 800px) {
    padding: 30px 0 0 10px;
  }

  .moreDeets__leftSection {
    max-width: 350px;
    h2,
    h2 span {
      font-size: 35px;
      color: #fff;
      font-family: "RaitorRegular";
      margin: 0;
      margin-bottom: 8px;

      @media (max-width: 500px) {
        font-size: 40px;
      }
    }

    h2 span {
      text-transform: uppercase;
      color: #ffae00;
    }

    .sub__hero__section {
      color: #d1dbe1;
      font-family: "MadeOuterMedium";
      font-size: 0.7rem;
    }

    p {
      color: #ffffff99;
      font-size: 12px;
      line-height: 22px;
      margin: 30px 0 10px 0;

      @media (max-width: 500px) {
        width: 90%;
      }
    }

    .moreDeets__leftSectionSub {
      .ant-checkbox-checked + span .links,
      .ant-checkbox-checked + span .links a {
        color: #e2f690 !important;
      }

      @media (max-width: 900px) {
        display: none;
      }
    }

    @media (min-width: 900px) {
      width: 35%;
      .mobileIntoText {
        display: none;
      }
    }
  }

  .moreDeets__rightSection {
    h3 {
      font-size: 30px;
      color: #e9f4a6;
      margin-bottom: 30px;
    }

    form {
      width: 100%;

      .ant-row,
      .ant-form-item {
        width: 100%;
        margin: 0 0 20px 0;
      }
      .doubleInputWrap {
        max-width: 600px;
      }
      & > div {
        @media (max-width: 900px) {
          & > div {
            width: 90%;
          }
          .doubleInputWrap {
            & > div {
              width: 100%;
            }
          }
        }
      }
    }

    .statusHeader {
      color: #ffffff;
      font-family: "MadeOuterMedium";
      font-size: 14px;
      margin: 10px 0 30px 0;
      @media (max-width: 500px) {
        width: 90%;
      }
    }

    .statusDotWrap {
      .statusDot {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: 1px solid #e6f59c;
        margin: 10px 4px;
      }
      .activeStatusDot {
        background: #e6f59c;
        border-color: #e6f59c;
      }
      @media (min-width: 900px) {
        display: none;
      }
    }
    .starusBoxWrap {
      max-width: 520px;
      .statusBox {
        width: 120px;
        height: 70px;
        border: 1px solid #707070;
        background: #0a0a0a;
        transition: all 0.3s ease;
        position: relative;
        border-radius: 0px;
        margin: 7px 10px 7px 0;
        cursor: pointer;
        img {
          width: 27px;
          opacity: 0.5;
        }
        p {
          color: #8f8f8f;
          font-family: "MadeOuterBold";
          margin: 5px 0 0 0;
          font-size: 10px;
        }

        :hover {
          border-color: #e6f59c;
        }
        ::before {
          content: "";
          width: 7px;
          height: 7px;
          border-radius: 100%;
          border: 1px solid #e6f59c;
          right: 10px;
          top: 10px;
          position: absolute;
        }
      }
      .activeStatusBox {
        background: #e6f59c;
        border-color: #e6f59c;

        img {
          opacity: 1;
        }
        small {
          color: #0a0a0a;
        }
        p {
          color: #0a0a0a;
        }
        ::before {
          background: #e6f59c;
          border-color: #e6f59c;
        }
      }
      @media (max-width: 900px) {
        .statusBox {
          width: 100px;
        }
      }
      @media (max-width: 370px) {
        .statusBox {
          width: 90px;
        }
      }
    }
    .socialsWrap {
      max-width: 520px;
      span {
        font-size: 14px;
        color: #ffffff;
        font-family: "MadeOuterMedium";
        margin: 20px 20px 0 0;
        @media (max-width: 900px) {
          display: none;
        }
      }
    }
    .buttonWrap {
      max-width: 550px;
    }
    .mobileHidenTextFields {
      p {
        color: #ffffff99;
        margin: 10px 0;
        font-size: 14px;
      }
    }

    @media (min-width: 900px) {
      width: 60%;
      .mobileHidenTextFields {
        display: none;
      }
    }
  }
`;
