import styled from "styled-components";

export const SideBarWrapper = styled.div`
  width: max-content;
  height: 100vh;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 5;
  padding: 40px 20px 20px 40px;
  .sidebar__main {
    position: relative;
    .logoLink {
      img {
        width: 70px;
        z-index: 9;
        @media (max-width: 800px) {
          display: none;
        }
      }
    }
    .sidebarItems__mainSub {
      justify-content: space-around;
      @media (max-width: 800px) {
        display: none;
      }
      .sideBarMainSubText {
        font-size: 15px;
        color: #cedbe2;
        font-family: "MadeOuterBold";
        opacity: 1;
        cursor: pointer;
        transition: opacity 0.3s ease;

        :hover {
          opacity: 1;
          &[data-glitch] {
            position: relative;
            &:before,
            &:after {
              content: attr(data-glitch);
              position: absolute;
              left: 0;
              right: 0;
              top: -1px;
              opacity: 0.8;
            }
            &:after {
              color: #f0f;
              z-index: -2;
              animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
                infinite reverse both;
            }
            &:before {
              color: #0ff;
              z-index: -1;
              animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
                infinite both;
            }
          }
          @keyframes glitch {
            0% {
              transform: translate(0);
            }
            20% {
              transform: translate(-2px, 2px);
            }
            40% {
              transform: translate(-2px, -2px);
            }
            60% {
              transform: translate(2px, 2px);
            }
            80% {
              transform: translate(2px, -2px);
            }
            to {
              transform: translate(0);
            }
          }
        }
      }
      .menu {
        margin: 0;
        font-family: "MadeOuterRegular";
        color: #ffffff;
        opacity: 1;
      }
      .showMenu {
        .menuLines {
          height: 2px;
          width: 30px;
          background: #e2f790;
        }
        .short {
          width: 20px;
        }
      }
      .slant_text {
        transform: rotate(-90deg);
        font-family: "RaitorRegular" !important;
        margin-left: -40px;
        .text__stroke {
          margin: 0 4px;
          color: #fdb91399;
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
  }

  .sidebar__hiddenMenu {
    position: absolute;
    width: 100vw;
    min-width: 100vw;
    height: 100vh;
    background: #000;
    top: 0;
    right: 0;
    left: ${({ showMenuItems }) => (showMenuItems ? "0" : "100vw")};
    transition: all 0.3s ease;
    padding-left: 40px;
    @media (max-width: 800px) {
      display: none;
    }

    #nav-cancel {
      margin-top: 60px;
      cursor: pointer;
      div {
        width: 40px;
        height: 2px;
        background: #e6f59c;
      }
      div:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        animation: ${({ showMenuItems }) => (showMenuItems ? "move 1s 1" : "")};
      }
      div:nth-child(2) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        animation: ${({ showMenuItems }) =>
          showMenuItems ? "movezleft 1s 1" : ""};
      }

      @keyframes move {
        from {
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        to {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      @keyframes movezleft {
        from {
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        to {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }
    .sidebar__hiddenMenu__itemsWrap {
      position: relative;
      .sidebar__hiddenMenu__itemsWrapSub {
        /* border-top: 1px solid #ffffff99; */
        justify-content: space-between;
        z-index: 2;

        a {
          padding: 60px 20px 0 20px;
          text-decoration: none;
          color: #ffffff99;
          font-size: 18px;
          transition: all 0.3s ease;

          span {
            font-family: "RaitorRegular";
          }

          :hover {
            color: #e9f4a6;
          }

          @media (max-width: 960px) {
            font-size: 14px;
            padding: 50px 20px;
          }
        }
        .active {
          color: #e9f4a6;
          font-weight: bold;
          border-top: 2px solid #e9f4a6;
          margin-top: -1px;
        }
      }
      .lines {
        position: absolute;
        left: 0;
        top: 0;
        background: transparent;
        height: 100vh;
        z-index: 1;

        div {
          height: 100%;
          width: 20%;
          border-left: 1px solid #cd910029;
        }
      }
    }
  }

  .layoutSectionMarker {
    position: absolute;
    left: 30px;
    top: 45%;
    min-width: 5px;
    z-index: 1;
    border: 1px solid #ffffff99;
    border-radius: 10px;
    div {
      background: #e6f59c;
      transition: all 0.3s ease;
      z-index: 1;
    }

    @media (max-width: 800px) {
      left: -25px;
      top: 45%;
      height: 200px;
    }
  }

  .thanksMarker {
    left: 80px;
    @media (min-width: 800px) and (max-width: 1110px) {
      display: none;
    }

    @media (max-width: 800px) {
      left: -25px;
    }
  }
`;
