/***
 *
 * validate email
 *returns validated email
 *@param {input string}
 * ****/
const validateEmail = (rule, value, setStatus) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({ err: "We’re going to need a way to contact you 😇" });
      reject("We’re going to need a way to contact you 😇");
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
    ) {
      setStatus({
        err: "Please re-enter your email address.",
      });

      reject("Please re-enter your email address.");
    } else {
      setStatus({ success: "success" });
      resolve();
    }
  });
};

const validateSpotify = (rule, value, setStatus) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus();
      resolve();
    } else if (
      !/^(?:https:\/\/open\.spotify\.com|spotify)([\/:])user\1([^\/]+)\1playlist\1([a-z0-9]+)/.test(
        value
      )
    ) {
      setStatus({
        err: "Our spidey senses tell us there’s something wrong with this url",
      });
      reject("Our spidey senses tell us there’s something wrong with this url");
    } else {
      setStatus({ success: "success" });
      resolve();
    }
  });
};

const validateYoutube = (rule, value, setStatus) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus();
      resolve();
    } else if (
      !/(?:https?:\/\/)?(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9\_-]+)/.test(
        value
      )
    ) {
      setStatus({
        err: "Our spidey senses tell us there’s something wrong with this url",
      });
      reject("Our spidey senses tell us there’s something wrong with this url");
    } else {
      setStatus({ success: "success" });

      resolve();
    }
  });
};

const validateInstagram = (rule, value, setStatus) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus();
      resolve();
    } else if (
      !/(?:(?:http|https):\/\/)(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/.test(
        value
      )
    ) {
      setStatus({
        err: "Our spidey senses tell us there’s something wrong with this url",
      });
      reject("Our spidey senses tell us there’s something wrong with this url");
    } else {
      setStatus({ success: "success" });
      resolve();
    }
  });
};
const validateFacebook = (rule, value, setStatus) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus();
      resolve();
    } else if (
      !/((?:http|https):\/\/)(?:www|m|mbasic|0|free)(.facebook.com\/)((?:[a-zA-Z0-9.]+)?$|(?:profile.php\?id=\w+([0-9]*)))/.test(
        value
      )
    ) {
      setStatus({
        err: "Our spidey senses tell us there’s something wrong with this url",
      });
      reject("Our spidey senses tell us there’s something wrong with this url");
    } else {
      setStatus({ success: "success" });

      resolve();
    }
  });
};

const validateTiktok = (rule, value, setStatus) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus();
      resolve();
    } else if (
      !/((?:http|https):\/\/)?(|tiktok.com)\/\/?(\S+\/+[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~a-zA-Z\-0-9])([0-9]+)?/.test(
        value
      )
    ) {
      setStatus({
        err: "Our spidey senses tell us there’s something wrong with this url",
      });
      reject("Our spidey senses tell us there’s something wrong with this url");
    } else {
      setStatus({ success: "success" });
      resolve();
    }
  });
};

const validateLink = (rule, value, setStatus) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus();
      resolve();
    } else if (
      !/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(
        value
      )
    ) {
      setStatus({
        err: "Our spidey senses tell us there’s something wrong with this url",
      });

      reject("Our spidey senses tell us there’s something wrong with this url");
    } else {
      setStatus({ success: "success" });
      resolve();
    }
  });
};

const validateProjectLink = (rule, value, setStatus) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        err: "We need an active link to listen to your work and no… Wetransfer doesn’t count",
      });
      reject(
        "We need an active link to listen to your work and no… Wetransfer doesn’t count"
      );
    } else if (
      !/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(
        value
      )
    ) {
      setStatus({
        err: "Our spidey senses tell us there’s something wrong with this url",
      });
      reject("Our spidey senses tell us there’s something wrong with this url");
    } else {
      setStatus({ success: "success" });
      resolve();
    }
  });
};

const validateFirstName = (e, value, setStatus) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        err: "We’re going to need your first name to proceed with this application",
      });
      reject(
        "We’re going to need your first name to proceed with this application"
      );
    } else if (value.length > 20) {
      setStatus({
        err: "We’re pretty sure your first name contains less than 20 letters",
      });

      reject("We’re pretty sure your first name contains less than 20 letters");
    } else if (value.length < 3) {
      setStatus({
        err: "We’re pretty sure your first name contains more than 3 letters",
      });

      reject("We’re pretty sure your first name contains more than 3 letters");
    } else if (
      !!/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value) ||
      /\d/.test(value)
    ) {
      setStatus({
        err: "We’re pretty sure your first name does not contain numbers or special characters",
      });

      reject(
        "We’re pretty sure your first name does not contain numbers or special characters"
      );
    } else {
      setStatus({ success: "success" });
      resolve();
    }
  });
};

const validateLastName = (rule, value, setStatus) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        err: "We’re going to need your last name to proceed with this application",
      });
      reject(
        "We’re going to need your last name to proceed with this application"
      );
    } else if (value.length > 20) {
      setStatus({
        err: "We’re pretty sure your last name contains less than 20 letters",
      });

      reject("We’re pretty sure your last name contains less than 20 letters");
    } else if (value.length < 3) {
      setStatus({
        err: "We’re pretty sure your last name contains more than 3 letters",
      });

      reject("We’re pretty sure your last name contains more than 3 letters");
    } else if (
      !!/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value) ||
      /\d/.test(value)
    ) {
      setStatus({
        err: "We’re pretty sure your last name does not contain numbers or special characters",
      });

      reject(
        "We’re pretty sure your last name does not contain numbers or special characters"
      );
    } else {
      setStatus({ success: "success" });
      resolve();
    }
  });
};

const validateMoniker = (rule, value, setStatus) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({ err: "We’ll need your stage/artist name to proceed…" });
      reject("‘We’ll need your stage/artist name to proceed…’");
    } else if (value.length > 20) {
      setStatus({
        err: "That’s a pretty long name… Maybe try an acronym 😂",
      });
      reject("That’s a pretty long name… Maybe try an acronym 😂");
    } else {
      setStatus({ success: "success" });
      resolve();
    }
  });
};
const validateAge = (rule, value, setStatus) => {
  const val = parseInt(value);
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({ err: "we'd like to know your age please" });
      reject("we'd like to know your age please");
    } else if (val < 16) {
      setStatus({
        err: "We’re not open to artists under 16. Yet… Please ask a parent/guardian to fill out this form on your behalf",
      });
      reject(
        "We’re not open to artists under 16. Yet… Please ask a parent/guardian to fill out this form on your behalf"
      );
    } else if (val > 99) {
      setStatus({ err: "We’re not open to artists above 99" });
      reject("We’re not open to artists above 99");
    } else if (
      value.includes(".") ||
      value.includes("+") ||
      value.includes(".")
    ) {
      setStatus({ err: "Our algorithms only accept dual digit numbers (0-9)" });
      reject("Our algorithms only accept dual digit numbers (0-9)");
    } else {
      setStatus({ success: "success" });
      resolve();
    }
  });
};

const validateProjectName = (rule, value, setStatus) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      setStatus({
        err: "Even if you don’t have a name yet, we can help with that 😎",
      });

      reject("Even if you don’t have a name yet, we can help with that 😎");
    } else if (value.length > 60) {
      setStatus({
        err: "We accept letters,numbers anything - it just has to be below 60 characters",
      });
      reject(
        "We accept letters,numbers anything - it just has to be below 60 characters  "
      );
    } else {
      setStatus({ success: "success" });
      resolve();
    }
  });
};

export {
  validateEmail,
  validateYoutube,
  validateSpotify,
  validateLink,
  validateProjectLink,
  validateLastName,
  validateFirstName,
  validateMoniker,
  validateAge,
  validateInstagram,
  validateFacebook,
  validateProjectName,
  validateTiktok,
};
