import styled from "styled-components";
import { Select } from "antd";

export default styled(Select)`
  position: relative;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selector {
    height: ${({ height }) => height || "50px !important"};
    background: ${({ background }) => background || "transparent !important"};
    border: ${({ border }) => border || "1px solid #707070 !important"};
    border-radius: ${({ radius }) => radius || "6px !important"};
    font-size: 14px;
    color: #ffffff;
    width: 100%;
    box-shadow: none;
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: #ffffff !important;
    font-family: "MadeOuterLight";
    text-transform: inherit;
    margin-top: 9px;
    height: 100%;
    & > div {
      & > span {
        & > div {
          margin-top: 5px;
        }
      }
    }
  }
  .ant-select-selection-item {
    color: #ffffff !important;
    .ant-checkbox {
      display: none !important;
    }
    span {
      padding-left: 0;
      padding-right: 0;
      p {
        font-size: 14px;
        max-width: 265px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .ant-select-selection-search-input {
    margin-top: 5px;
    color: #ffffff;
  }
  .ant-select-arrow {
    svg {
      color: #ffffff;
    }
  }
  input {
    margin-top: 10px !important;
  }
`;
