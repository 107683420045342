import styled from "styled-components";

export const MobileHeaderWrapper = styled.div`
  width: 100%;
  height: ${({ showMenu }) => (showMenu ? "100vh" : "60px")};
  box-sizing: border-box;
  transition: all 0.3s ease;
  position: ${({ showMenu }) => (showMenu ? "fixed" : "absolute")};
  background: ${({ showMenu }) => (showMenu ? "#000" : "transparent")};
  left: 0;
  top: 0;
  padding: 0 10px;
  z-index: 999999;
  overflow: hidden;

  & > div {
    .lines {
      position: absolute;
      left: 0;
      top: 0;
      background: transparent;
      z-index: 1;
      transition: all 0.3s ease;
      height: ${({ showMenu }) => (showMenu ? "100vh" : "0")};

      div {
        height: 100%;
        width: 20%;
        border-left: 1px solid #fdb2000f;
      }
    }
    .header__main {
      height: 60px;
      min-height: 60px;
      padding: 0 5%;
      z-index: 2;
      align-items: flex-end;

      img {
        width: 90px;
      }
      svg {
        color: #fcb316;
        font-size: 25px;
      }
      .showMenu {
        .menuLines {
          width: 2px;
          height: 30px;
          background: #fcb316;
        }
        .short {
          height: 20px;
        }
      }
    }

    .menuItems {
      padding: 50px 5%;
      box-sizing: border-box;
      height: calc(100vh - 60px);
      z-index: 2;
      align-items: flex-start;

      a {
        color: #fcb316;
        margin-bottom: 40px;
        font-size: 14px;
        text-decoration: none;
        color: #ffffff99;
      }
      .active {
        color: #fcb316;
      }
    }
  }

  @media (min-width: 800px) {
    display: none;
  }
`;
