import React, { useState } from "react";
import { FormItem, IconWrap } from "./textfield.styles";
import { FaCheckCircle as Check } from "react-icons/fa";
import { MdCancel as Cancel } from "react-icons/md";
import { Button, Tooltip } from "antd";

const TextField = ({
  name,
  validator,
  placeholder,
  direction,
  type,
  borderwidth,
  height,
  radius,
  padding,
}) => {
  const [status, setStatus] = useState();

  return (
    <React.Fragment>
      <FormItem
        name={name}
        rules={
          direction !== "back" && [
            {
              validator: (rules, value) => validator(rules, value, setStatus),
            },
          ]
        }
        border={status?.err && "1px solid #f54b4b"}
        borderwidth={borderwidth}
        height={height}
        radius={radius}
        padding={padding}
      >
        <input
          onChange={(e) => validator(e, e?.target?.value, setStatus)}
          onBlur={(e) => validator(e, e?.target?.value, setStatus)}
          placeholder={placeholder}
          type={type || "text"}
        />
      </FormItem>
      <IconWrap height={height}>
        {status?.err && (
          <Tooltip placement="bottomRight" title={status?.err}>
            <Button>
              <Cancel className="cancel" />
            </Button>
          </Tooltip>
        )}
        {status?.success && <Check className="check" />}
      </IconWrap>
    </React.Fragment>
  );
};

export default TextField;
