import Manager from "../../assets/img/pngs/managr.png";
import Artist from "../../assets/img/pngs/artist.png";
import Label from "../../assets/img/pngs/label.png";

export const status = [
  {
    title: "Manager",
    text: "The person behind the scenes",
    icon: Manager,
  },
  {
    title: "Artist",
    text: "Vibe Creator - sing, rap, trap, mumble + Vibes",
    icon: Artist,
  },
  {
    title: "Label",
    text: "One of the good guys promoting dope music",
    icon: Label,
  },
];
