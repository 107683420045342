import styled from "styled-components";
import Bg from "../../assets/img/pngs/thanks_smallImage_2.png";

export const ThanksStyles = styled.div`
  width: 100%;
  padding: 100px 30px 30px 30px;
  @media (max-width: 1000px) and (min-width: 900px) {
    padding: 30px 0 0 10px;
  }
  @media (max-width: 800px) {
    padding: 30px 5% 0 10px;
  }

  .thanks__leftSection {
    max-width: 350px;
    width: 35%;

    h2,
    h2 span {
      font-size: 33px;
      color: #fff;
      font-family: "RaitorRegular";
      margin: 0;

      @media (max-width: 950px) {
        font-size: 40px;
      }
    }

    h2 span {
      color: #e6f59c;
      font-size: 28px;
      text-transform: uppercase;
    }

    .thanks__leftSectionSub {
      p {
        color: #ffffff;
        font-size: 12px;
        line-height: 22px;
        margin: 30px 0 10px 0;
      }
    }
    @media (max-width: 800px) {
      display: none;
    }
  }

  .thanks__rightSection {
    transition: all 0.3s ease;
    .rigthSection__top {
      border: 1px solid #fcb316;
      border-width: 1px 1px 0 1px;
      padding: 10px 0 7px 0;

      .rigthSection__topInner {
        background-image: url(${Bg});
        background-size: cover;
        background-position: center;
        padding: 40px 5%;
        overflow: hidden;
        h3 {
          font-size: 30px;
          text-align: center;
          color: #fff;
          margin: 0;
          width: 80%;
          h3 {
            font-family: "ModellicaBold2", sans-serif;
            width: 100%;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 auto;
            line-height: 40px;
          }
        }
      }
    }
    .rigthSection__bottom {
      background: #e5dfd1;
      padding: 40px 10% 20px 10%;
      @media (max-width: 650px) {
        padding: 40px 5%;
      }

      p {
        color: #000000;
        font-size: 12px;
        line-height: 22px;
        text-align: center;
        font-family: "MadeOuterMedium";
      }
      svg {
        font-size: 20px;
        color: #ffae00;
        transition: all 0.3s ease;

        :hover {
          opacity: 0.7;
        }
      }
    }

    @media (min-width: 800px) {
      width: 60%;
      .rightSection__sub {
        max-width: 400px;
      }
      .mobileHidenInputs {
        display: none;
      }
      .mobile {
        display: none;
      }
    }

    @media (max-width: 800px) {
      overflow-x: auto;
      justify-content: flex-start;
      ::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      .rightSection__sub {
        transition: all 0.3s ease;
        width: 290px;
        min-width: 290px;
        border: 2px solid #8f8f8f;
        height: max-content;

        .links {
          display: none;
        }
        .rigthSection__top {
          height: 200px;
          border: none;
          .rigthSection__topInner {
            align-items: flex-start;
            height: 184px;
            h3 {
              font-size: 22px;
            }
          }
        }
        .rigthSection__bottom {
          height: 370px;
          justify-content: flex-start;
        }
      }
      .mobile {
        margin-left: 30px;
        .rigthSection__top {
          height: 200px;
          padding: 10px 0;
        }
      }
      #active {
        border: 2px solid #ffae00;
      }
    }
  }

  .statusDotWrap {
    .statusDot {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      border: 1px solid #ffffff99;
      margin: 0 4px 30px 4px;
      transition: all 0.3s ease;
    }
    .activeStatusDot {
      background: #ffae00;
      border-color: #ffae00;
    }

    @media (min-width: 800px) {
      display: none;
    }
  }

  button {
    :hover {
      &[data-glitch] {
        &:before,
        &:after {
          content: attr(data-glitch);
          position: absolute;
          left: 12%;
        }
      }
    }
  }
`;
