import EP from "../../assets/img/pngs/ep.png";
import Album from "../../assets/img/pngs/album.png";

export const whyPoprev = [
  "I love the business model ",
  "I want to own my masters ",
  "I need access to funding",
  "I believe the industry is changing",
];
export const distributionDeal = ["Yes, I have one", "No, I do not"];
export const devPhase = [
  "Conceptualisation",
  "Still working on tracks",
  "Requires mix & master",
  " Ready to go",
];
export const aboutUs = [
  "Social media ",
  "Google search",
  "Ads",
  "Word of mouth",
  "Print, News Articles, Press",
];

export const status = [
  {
    title: "EP",
    text: "4-6 Track project",
    icon: EP,
  },
  {
    title: "ALBUM",
    text: "A FULL BODY OF WORK",
    icon: Album,
  },
];
export const checkListData = ["Artist Management", "Marketing & PR", "A&R"];

export const days = [
  1, 2, 3, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];
export const months = [
  {
    title: "January",
    value: "01",
  },
  {
    title: "February",
    value: "02",
  },
  {
    title: "March",
    value: "03",
  },
  {
    title: "April",
    value: "04",
  },
  {
    title: "May",
    value: "05",
  },
  {
    title: "June",
    value: "07",
  },
  {
    title: "July",
    value: "08",
  },
  {
    title: "August",
    value: "09",
  },
  {
    title: "September",
    value: "10",
  },
  {
    title: "October",
    value: "11",
  },
  {
    title: "December",
    value: "12",
  },
];
