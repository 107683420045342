import React, { useState, useEffect } from "react";
import { SideBarWrapper } from "./styles";
import { FlexibleDiv } from "../../Box/styles";
import { menuItems } from "../../../utils/dataHelpers/menuItems";
import { NavLink, useLocation, Link } from "react-router-dom";
import Logo from "../../../assets/img/svgs/logo.svg";
import gsap from "gsap";
import splitting from "splitting";

const SideBar = ({ sectionMarker }) => {
  const [showMenuItems, setShowMenuItems] = useState(false);
  const location = useLocation();
  const active = location.pathname;

  useEffect(() => {
    if (!!showMenuItems) {
      splitting({
        by: "words",
        target: "#sidebar__hiddenMenu__itemsWrapSub [data-splitting]",
      });
      const TL = gsap.timeline();
      const sidebar__hiddenMenu__itemsWrapSub = gsap.utils.selector(
        "#sidebar__hiddenMenu__itemsWrapSub"
      );
      TL.set("#sidebar__hiddenMenu__itemsWrapSub", { visibility: "visible" })
        .set(sidebar__hiddenMenu__itemsWrapSub("[data-splitting]"), {
          visibility: "visible",
        })
        .to("#sidebar__hiddenMenu__itemsWrapSub", {
          delay: 0,
          opacity: 1,
          duration: 1,
          ease: "circ.out",
        })
        .from(
          sidebar__hiddenMenu__itemsWrapSub(".word, .whitespace"),
          {
            duration: 2,
            opacity: 0,
            yPercent: -30,
            stagger: 0.08,
            ease: "expo.out",
            // ease: "power2.out",
          },
          0.3
        );
    }
  }, [showMenuItems]);

  return (
    <SideBarWrapper showMenuItems={showMenuItems} sectionMarker={sectionMarker}>
      <FlexibleDiv
        flexDir="column"
        alignItems="flex-start"
        height="100%"
        flexWrap="nowrap"
        justifyContent="space-between"
        className="sidebar__main"
      >
        <Link to="/" className="logoLink">
          <img src={Logo} alt="" />
        </Link>
        {active === "/thanks" ? (
          <>
            {!showMenuItems && (
              <FlexibleDiv
                className="layoutSectionMarker thanksMarker"
                width="5px"
                height="170px"
                alignItems="flex-start"
              >
                <FlexibleDiv height={sectionMarker} width="6px"></FlexibleDiv>
              </FlexibleDiv>
            )}

            <FlexibleDiv
              flexDir="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              height="70%"
              flexWrap="nowrap"
              className="sidebarItems__mainSub"
            >
              <FlexibleDiv
                width="max-content"
                flexDir="column"
                onClick={() => setShowMenuItems(!showMenuItems)}
                alignItems="flex-start"
              >
                <p data-glitch={"Menu"} className="sideBarMainSubText menu">
                  Menu <br />
                </p>
                <FlexibleDiv
                  className="menuIcon showMenu"
                  width="max-content"
                  height="9px"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  flexDir="column"
                  margin="5px 2px"
                >
                  <div className="menuLines long"></div>
                  <div className="menuLines short"></div>
                </FlexibleDiv>
              </FlexibleDiv>

              <p className="slant_text">
                <span className="sideBarMainSubText" data-glitch={"Artists"}>
                  Artists
                </span>{" "}
                <span className="text__stroke">|</span>{" "}
                <span className="sideBarMainSubText" data-glitch={"Invest"}>
                  Invest
                </span>
              </p>
            </FlexibleDiv>
          </>
        ) : (
          <>
            {sectionMarker ? (
              <FlexibleDiv
                className="layoutSectionMarker"
                width="5px"
                height="170px"
                alignItems="flex-start"
              >
                <FlexibleDiv
                  height={sectionMarker}
                  width="6px"

                  // margin="0 -1px 0 0"
                ></FlexibleDiv>
              </FlexibleDiv>
            ) : (
              <>
                <FlexibleDiv
                  flexDir="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  height="70%"
                  flexWrap="nowrap"
                  className="sidebarItems__mainSub"
                >
                  <FlexibleDiv
                    width="max-content"
                    flexDir="column"
                    // className="sideBarMainSubText"
                    onClick={() => setShowMenuItems(!showMenuItems)}
                    alignItems="flex-start"
                  >
                    <p data-glitch={"Menu"} className="sideBarMainSubText menu">
                      Menu <br />
                    </p>
                    <FlexibleDiv
                      className="menuIcon showMenu"
                      width="max-content"
                      height="9px"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      flexDir="column"
                      margin="5px 2px"
                    >
                      <div className="menuLines long"></div>
                      <div className="menuLines short"></div>
                    </FlexibleDiv>
                  </FlexibleDiv>

                  <p className="slant_text">
                    <span
                      className="sideBarMainSubText"
                      data-glitch={"Artists"}
                    >
                      Artists
                    </span>{" "}
                    <span className="text__stroke">|</span>{" "}
                    <span className="sideBarMainSubText" data-glitch={"Invest"}>
                      Invest
                    </span>
                  </p>
                </FlexibleDiv>
              </>
            )}
          </>
        )}
      </FlexibleDiv>
      <FlexibleDiv className="sidebar__hiddenMenu" alignItems="flex-start">
        <FlexibleDiv height="90%" justifyContent="space-between">
          <div id="nav-cancel" onClick={() => setShowMenuItems(!showMenuItems)}>
            <div></div>
            <div></div>
          </div>

          <FlexibleDiv
            width="80%"
            className="sidebar__hiddenMenu__itemsWrap"
            height="100%"
          >
            <FlexibleDiv
              className="sidebar__hiddenMenu__itemsWrapSub"
              id="sidebar__hiddenMenu__itemsWrapSub"
            >
              {menuItems.map((item, idx) => (
                <NavLink
                  to={item.url}
                  className={active === item.url ? "active" : ""}
                  key={idx}
                  id="link"
                  data-splitting=""
                >
                  {item.title}
                </NavLink>
              ))}
            </FlexibleDiv>

            <FlexibleDiv className="lines" justifyContent="flex-start">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </SideBarWrapper>
  );
};

export default SideBar;
