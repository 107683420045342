import React, { useState, useEffect, useContext } from "react";
import LandingLayout from "../../components/Layout/layout";
import { MoreDeetsStyles } from "./moreDeets.styles";
import BgImage from "../../assets/img/pngs/moreDeetsImage.png";
import { FlexibleDiv, Section } from "../../components/Box/styles";
import TextField from "../../components/TextField";
import { Form, notification } from "antd";
import Button from "../../components/Button";
import { AiOutlineArrowLeft as LeftArrow } from "react-icons/ai";
import Checkbox from "../../components/CheckBox";
import { CURRENT_USER } from "../../context/types";
import { SmileOutlined, LoadingOutlined } from "@ant-design/icons";
import { MainContext } from "../../context/MainContext";
import { status } from "../../utils/dataHelpers/moreDeets";
import { useNavigate, useLocation } from "react-router-dom";
import { signupUser } from "../../network/user";
import {
  validateSpotify,
  validateLink,
  validateYoutube,
} from "../../utils/fuctionHelpers";

const MoreDeets = ({ setActivePage }) => {
  const [genre, setGenre] = useState([]);
  const [form] = Form.useForm();
  const [checkList, setCheckList] = useState(0);
  const [confirmChecklist, setConfirmChecklist] = useState();
  const [confirmGenre, setConfirmGenre] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const {
    dispatch,
    state: { user },
  } = useContext(MainContext);
  const [direction, setDirection] = useState("");

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
  }, []);

  const handleSubmit = async (values) => {
    if (direction === "back") {
      const payload = { ...values, progect_genre: [...genre] };
      await dispatch({ type: CURRENT_USER, payload: { ...user, ...payload } });
      navigate("/project", { state: "submission-complete" });
    } else {
      if (checkList < 4) {
        setConfirmChecklist(
          "We cannot process your application till you agree to the terms & conditions"
        );
        return;
      }
      if (genre?.length < 2) {
        setConfirmGenre(
          "You can choose between 2-4 choices, We apologise if we haven’t captured your genre! Choose one as close to your sound as possible"
        );
        return;
      }
      if (
        !values?.spotify_link &&
        !values?.youtube_link &&
        !values?.other_link
      ) {
        notification.open({
          message: "Error",
          description: "Please include at least one project link",
          icon: <SmileOutlined style={{ color: "red" }} rotate="180" />,
        });
        return;
      }

      setLoading(true);
      const payload = { ...values, project_genre: genre };

      const dataPayload = {
        social_media: {
          instagram: user?.instagram,
          tik_tok: user?.tik_tok,
          facebook: user?.facebook,
        },
        projects: [
          {
            project_status: {
              project_dev_phase: user?.project_dev_phase,
              distribution_deal: user?.distribution_deal,
              release_date: `${user?.release_date?.year}-${user?.release_date?.month}-${user?.release_date?.day}`,
            },
            project_links: {
              private_link: user?.private_project_link,
              spotify_link: payload?.spotify_link,
              youtube_link: payload?.youtube_link,
              other_link: payload?.other_link,
            },
            artist_status: {
              artist_type: user?.applicant_type,
              signed_status: user?.signed_status,
              artist_need: user?.applicant_need,
            },
            extra_info: {
              why_pop_rev: user?.why_pop_rev,
              how_pop_rev: user?.how_pop_rev,
            },
            project_name: user?.project_name,
            project_type: user?.project_type,
            project_genre: payload?.project_genre,
          },
        ],
        email: user?.email,
        first_name: user?.first_name,
        last_name: user?.last_name,
        moniker: user?.moniker,
        gender: user?.gender,
        age: parseInt(user?.age),
        country_of_residence: user?.country_of_residence,
      };

      try {
        await signupUser(dataPayload);
        await dispatch({ type: CURRENT_USER, payload: {} });
        setLoading(false);
        window.location.href = "/thanks";
        localStorage.setItem(
          "userdata",
          JSON.stringify({
            username: user?.moniker,
            project_name: user?.project_name,
          })
        );
      } catch (error) {
        if (error.response) {
          notification.open({
            message: "Error",
            description: error.response.data.error,
            icon: <SmileOutlined style={{ color: "red" }} rotate="180" />,
          });

          setLoading(false);
        } else {
          notification.open({
            message: "Error",
            description: "Network error",
            icon: <SmileOutlined style={{ color: "red" }} rotate="180" />,
          });
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    function fillFormDetails() {
      try {
        form.setFieldsValue({
          spotify_link: user?.spotify_link || "",
          other_link: user?.other_link || "",
          youtube_link: user?.youtube_link || "",
        });
        setGenre(user.project_genre || []);
      } catch (error) {}
    }
    fillFormDetails();
  }, [user]);

  const handleSetChecklist = (e) => {
    const val = checkList;
    if (!!e.target.checked) {
      setCheckList(val + 1);
    } else {
      setCheckList(val - 1);
    }
  };

  const checkListData = [
    "I agree to PopRev",
    `All information I’ve provided is true`,
    "I consent for my data to be processed",
    "I want to be contacted by PopRev about my application and other opportunities",
  ];

  const handleGenre = (value) => {
    if (genre.length === 4 && !genre.includes(value)) {
      notification.open({
        message: "Error",
        description:
          "You can choose between 2-4 choices, We apologise if we haven’t captured your genre! Choose one as close to your sound as possible  ",
        icon: <SmileOutlined style={{ color: "red" }} rotate="180" />,
      });
      return;
    }
    if (genre.includes(value)) {
      genre.splice(genre.indexOf(value), 1);
      setGenre([...genre]);
    } else {
      setGenre([...genre, value]);
    }
  };

  return (
    <LandingLayout
      bgImage={BgImage}
      bgText="GENRES"
      smallerText
      sectionMarker={"75%"}
      bgTextSize={130}
      darkerBg
    >
      <MoreDeetsStyles>
        <FlexibleDiv
          justifyContent="space-between"
          className="moreDeets__mainWrap"
        >
          <Section
            flexDir="column"
            className="moreDeets__leftSection"
            alignItems="flex-start"
          >
            <h2>
              Let’s Talk
              <br />
              <span>Sound</span>
            </h2>
            <span className="sub__hero__section">
              To paint a better picture…..
              <br /> <br />
              We need all this information to build a profile around the project
              you’re submitting to our team.
            </span>

            <p className="mobileIntoText">
              WELCOME TO OUR OFFICIAL SUBMISSION PROCESS If you’d like to be a
              PopRev draft pick and have your project featured on PopRev Roster
              for funding,
            </p>
            <FlexibleDiv
              flexDir="column"
              alignItems="flex-start"
              className="moreDeets__leftSectionSub"
              margin="20px 0"
            >
              {checkListData.map((item, index) => (
                <FlexibleDiv justifyContent="flex-start">
                  <Checkbox onChange={handleSetChecklist}>
                    <span className="links">
                      {item}
                      {index === 0 && (
                        <a
                          target="_blank"
                          href="https://wearepoprev.com/project-submissions-tcs/"
                        >
                          {" "}
                          Terms & Conditions
                        </a>
                      )}
                    </span>
                  </Checkbox>
                </FlexibleDiv>
              ))}
              {confirmChecklist && checkList < 4 && (
                <FlexibleDiv justifyContent="flex-start" margin="10px 0">
                  <small className="confirmStatus">{confirmChecklist}</small>
                </FlexibleDiv>
              )}
            </FlexibleDiv>
          </Section>

          <Section
            flexDir="column"
            className="moreDeets__rightSection"
            alignItems="flex-start"
          >
            <h3>{user?.project_name}</h3>
            <p className="statusHeader">
              ‘How would you describe {user?.project_name}?
            </p>
            <Form form={form} onFinish={handleSubmit}>
              {confirmGenre && genre.length < 2 && (
                <FlexibleDiv
                  justifyContent="flex-start"
                  margin="10px 0"
                  width="90%"
                  maxWidth="500px"
                >
                  <small className="confirmStatus">{confirmGenre}</small>
                </FlexibleDiv>
              )}
              <FlexibleDiv
                justifyContent="flex-start"
                className="starusBoxWrap"
                margin="0 0 20px 0"
              >
                {status.map((item, idx) => (
                  <FlexibleDiv
                    className={
                      genre?.includes(item.title)
                        ? "statusBox activeStatusBox"
                        : "statusBox"
                    }
                    flexDir="column"
                    onClick={() => handleGenre(item.title)}
                  >
                    <img src={item.icon} alt="" />
                    <p>{item.title}</p>
                  </FlexibleDiv>
                ))}
              </FlexibleDiv>

              <p className="statusHeader">
                If we love it and want to check out some more of your work ….
              </p>

              <FlexibleDiv
                justifyContent="space-between"
                className="socialsWrap"
                alignItems="flex-start"
              >
                <span>Spotify</span>
                <FlexibleDiv width="77%" justifyContent="flex-end">
                  <TextField
                    placeholder="https://...spotify…"
                    name="spotify_link"
                    borderwidth="0 0 1px 0"
                    radius="0"
                    height="35px"
                    padding="0"
                    validator={validateSpotify}
                    direction={direction}
                  />
                </FlexibleDiv>
              </FlexibleDiv>
              <FlexibleDiv
                justifyContent="space-between"
                className="socialsWrap"
                alignItems="flex-start"
              >
                <span>Youtube</span>
                <FlexibleDiv width="77%" justifyContent="flex-end">
                  <TextField
                    placeholder="https://...youtube…"
                    name="youtube_link"
                    borderwidth="0 0 1px 0"
                    radius="0"
                    height="35px"
                    padding="0"
                    validator={validateYoutube}
                    direction={direction}
                  />
                </FlexibleDiv>
              </FlexibleDiv>
              <FlexibleDiv
                justifyContent="space-between"
                className="socialsWrap"
                alignItems="flex-start"
              >
                <span>Other</span>
                <FlexibleDiv width="77%" justifyContent="flex-end">
                  <TextField
                    placeholder="https://other…"
                    name="other_link"
                    borderwidth="0 0 1px 0"
                    radius="0"
                    height="35px"
                    padding="0"
                    validator={validateLink}
                    direction={direction}
                  />
                </FlexibleDiv>
              </FlexibleDiv>

              <FlexibleDiv
                flexDir="column"
                alignItems="flex-start"
                className="mobileHidenTextFields"
              >
                <p>I will need PopRev to help with</p>
                {checkListData.map((item, index) => (
                  <FlexibleDiv justifyContent="flex-start">
                    <Checkbox onChange={handleSetChecklist}>
                      <span className="links">
                        {item}
                        {index === 0 && (
                          <a
                            target="_blank"
                            href="https://wearepoprev.com/project-submissions-tcs/
                          "
                          >
                            Terms & Conditions
                          </a>
                        )}
                      </span>
                    </Checkbox>
                  </FlexibleDiv>
                ))}
                {confirmChecklist && checkList < 4 && (
                  <FlexibleDiv justifyContent="flex-start" margin="10px 0">
                    <small className="confirmStatus">{confirmChecklist}</small>
                  </FlexibleDiv>
                )}
              </FlexibleDiv>

              <FlexibleDiv
                className="buttonWrap"
                margin="40px 0 0 0"
                justifyContent="space-between"
                width="90%"
              >
                <Button
                  htmlType="submit"
                  onClick={() => setDirection("back")}
                  data-glitch={"Back"}
                  radius="0px"
                  backButton
                >
                  <LeftArrow /> Back
                </Button>

                <Button
                  htmlType="submit"
                  background="#E6F59C"
                  radius="0px"
                  color="#000000"
                  onClick={() => setDirection("forwards")}
                  data-glitch={loading ? "" : "Submit"}
                  SubmitButton
                >
                  {loading ? <LoadingOutlined /> : "Submit"}
                </Button>
              </FlexibleDiv>
            </Form>
          </Section>
        </FlexibleDiv>
      </MoreDeetsStyles>
    </LandingLayout>
  );
};

export default MoreDeets;
