import React, { useState, useEffect } from "react";
import { MobileHeaderWrapper } from "./styles";
import { FlexibleDiv } from "../../Box/styles";
import { menuItems } from "../../../utils/dataHelpers/menuItems";
import { NavLink, useLocation, Link } from "react-router-dom";
import Logo from "../../../assets/img/svgs/poprev-logo.svg";
import { CloseOutlined } from "@ant-design/icons";
import gsap from "gsap";
import splitting from "splitting";

const MobileHeader = ({ mobileMenu }) => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const active = location.pathname;

  const show = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    if (!!showMenu) {
      splitting({
        by: "words",
        target: "#menuItems [data-splitting]",
      });
      const TL = gsap.timeline();
      const menuItems = gsap.utils.selector("#menuItems");
      TL.set("#menuItems", { visibility: "visible" })
        .set(menuItems("[data-splitting]"), {
          visibility: "visible",
        })
        .to("#menuItems", {
          delay: 0,
          opacity: 1,
          duration: 1,
          ease: "circ.out",
        })
        .from(
          menuItems(".word, .whitespace"),
          {
            duration: 2,
            opacity: 0,
            yPercent: -30,
            stagger: 0.08,
            ease: "expo.out",
            // ease: "power2.out",
          },
          0.3
        );
    }
  }, [showMenu]);

  return (
    <MobileHeaderWrapper showMenu={showMenu}>
      <FlexibleDiv
        justifyContent="space-between"
        height="100%"
        flexWrap="nowrap"
        flexDir="column"
      >
        <FlexibleDiv justifyContent="space-between" className="header__main">
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>

          {mobileMenu && (
            <>
              {!!showMenu ? (
                <CloseOutlined onClick={show} className="menuIcon" />
              ) : (
                <FlexibleDiv
                  onClick={show}
                  className="menuIcon showMenu"
                  width="11px"
                  height="100%"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <div className="menuLines short"></div>
                  <div className="menuLines long"></div>
                </FlexibleDiv>
              )}
            </>
          )}
        </FlexibleDiv>
        <FlexibleDiv
          className="menuItems"
          flexDir="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          flexWrap="nowrap"
          id="menuItems"
        >
          {menuItems.map((item, idx) => (
            <NavLink
              to={item.url}
              key={idx}
              id={active === item.url ? "active" : ""}
              data-splitting=""
            >
              {item.title}
            </NavLink>
          ))}
        </FlexibleDiv>

        <FlexibleDiv className="lines" justifyContent="flex-start">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </FlexibleDiv>
      </FlexibleDiv>
    </MobileHeaderWrapper>
  );
};

export default MobileHeader;
