import styled from "styled-components";

export const JoinRevolutionStyles = styled.div`
  width: 100%;
  padding: 30px;
  @media (max-width: 1000px) and (min-width: 900px) {
    padding: 30px 0 0 10px;
  }
  @media (max-width: 800px) {
    padding: 30px 0 0 5px;
  }

  .joinRevolution__leftSection {
    max-width: 350px;

    h2,
    h2 span {
      font-size: 35px;
      font-family: "RaitorRegular";
      color: #fff;
      margin: 0;
      @media (max-width: 500px) {
        font-size: 25px;
      }
    }

    h2 span {
      color: #e9f4a6;
    }

    span {
      color: #fdfeff;
      font-size: 12px;
      line-height: 22px;
      margin: 10px 0;
      @media (max-width: 500px) {
        width: 90%;
      }
    }

    .sub__hero__section {
      color: #fcb316;
      text-transform: uppercase;
      font-family: "MadeOuterMedium";
    }

    @media (min-width: 900px) {
      width: 35%;
    }
  }

  .joinRevolution__rightSection {
    h3 {
      font-size: 30px;
      color: #d1dbe1;
      margin-bottom: 30px;
    }
    .sectionMarker {
      border: 1px solid #8f8f8f;
      border-radius: 10px;
      & > div {
        background: #e9f4a6;
      }
      @media (max-width: 800px) {
        display: none;
      }
    }
    form {
      width: 100%;

      .ant-row,
      & > div {
        @media (max-width: 900px) {
          & > div {
            width: 90%;
          }
          .doubleTextFieldWrap {
            & > div {
              width: 100%;
            }
          }
        }
      }
    }

    .statusHeader {
      color: #fff;
      font-size: 14px;
      font-family: "MadeOuterMedium";
      margin: 10px 0 30px 0;
      @media (max-width: 500px) {
        width: 90%;
      }
    }
    .statusDotWrap {
      .statusDot {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: 1px solid #ffffff99;
        margin: 10px 4px;
      }
      .activeStatusDot {
        background: #ffae00;
        border-color: #ffae00;
      }
      @media (min-width: 900px) {
        display: none;
      }
    }
    .starusBoxWrap {
      max-width: 700px;
      .statusBox {
        width: 200px;
        height: 220px;
        border: 1px solid #ffffff99;
        background: #00000099;
        transition: all 0.3s ease;
        position: relative;
        cursor: pointer;

        img {
          width: 100px;
          opacity: 0.5;
        }
        p {
          color: #ffffff99;
          font-size: 20px;
          font-family: "MadeOuterBold";
        }
        small {
          color: #ffffff99;
          font-size: 12px;
          font-family: "MadeOuterBold";
          text-align: center;
          width: 85%;
          min-height: 50px;
        }
        :hover {
          border-color: #ebf3af;
        }
        ::before {
          content: "";
          width: 7px;
          height: 7px;
          border-radius: 100%;
          border: 1px solid #ffffff99;
          right: 10px;
          top: 10px;
          position: absolute;
        }
      }
      .activeStatusBox {
        transform: scale(1.1);
        background: #ebf3af;
        border-color: #ffffff99;

        img {
          opacity: 1;
        }
        small {
          color: #0a0a0a;

          font-size: 0.65rem;
        }
        p {
          color: #0a0a0a;
        }
        ::before {
          background: #ebf3af;
          border-color: #ebf3af;
        }
      }
      @media (max-width: 900px) {
        padding: 15px 15px 15px 5px;
        .statusBox {
          min-width: 200px;
        }
        .activeStatusBox {
          transform: scale(1.05);
        }
        overflow-x: scroll;

        ::-webkit-scrollbar-thumb {
          background: transparent;
        }
        ::-webkit-scrollbar-thumb:active {
          background: transparent;
        }
        ::-webkit-scrollbar-track {
          background: transparent;
        }
        ::-webkit-scrollbar-track:hover {
          background: transparent;
        }
        ::-webkit-scrollbar-track:active {
          background: transparent;
        }
        ::-webkit-scrollbar-corner {
          background: transparent;
        }
      }
    }

    .buttonWrap {
      @media (min-width: 900px) {
        justify-content: flex-end;
      }
    }

    @media (min-width: 900px) {
      width: 60%;
    }
  }

  .links {
    padding-left: 25%;
    margin-bottom: 50px;
    a {
      color: #ffffff;
      position: relative;
      font-size: 12px;
      margin-bottom: 10px;
      transition: all 0.3s ease;
      :hover {
        opacity: 0.7;
      }

      ::before {
        content: "";
        width: 40px;
        height: 1.5px;
        background: #fdb913;
        position: absolute;
        left: -50px;
        top: 50%;
      }
    }
  }
`;
