export const menuItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Artists",
    url: "/artists",
  },
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Draft Picks",
    url: "/draft-picks",
  },
  {
    title: "FAQ's",
    url: "/faqs",
  },
  {
    title: "Market Overview",
    url: "/market-overview",
  },
];
