import styled from "styled-components";

export const LetsTalkStyles = styled.div`
  width: 100%;
  padding: 30px;
  @media (max-width: 1000px) and (min-width: 900px) {
    padding: 30px 0 0 10px;
  }
  @media (max-width: 800px) {
    padding: 30px 0 0 10px;
  }

  .letsTalk__leftSection {
    max-width: 350px;
    h2,
    h2 span {
      font-size: 35px;
      font-family: "RaitorRegular";
      color: #fff;
      margin: 0;
      margin-bottom: 15px;

      @media (max-width: 500px) {
        font-size: 40px;
      }
    }

    h2 span {
      color: #ffae00;
    }

    .sub__hero__section {
      color: #d1dbe1;
      text-transform: uppercase;
      font-family: "MadeOuterMedium";
    }

    p {
      color: #fdfeff;
      font-size: 12px;
      line-height: 22px;
      margin: 15px 0 15px 0;
      max-width: 280px;
      @media (max-width: 500px) {
        width: 90%;
      }
    }

    #popRev__help {
      max-width: fit-content;
    }

    .ant-checkbox-wrapper-checked {
      .ant-checkbox + span .links {
        color: #e2f690 !important;
      }
    }

    .letsTalk__leftSectionSub {
      @media (max-width: 900px) {
        display: none;
      }
    }

    @media (min-width: 900px) {
      width: 35%;
      .mobileIntoText {
        display: none;
      }
    }
  }

  .letsTalk__rightSection {
    h3 {
      font-size: 30px;
      color: #e9f4a6;
      margin-bottom: 30px;
      @media (max-width: 900px) {
        margin: 30px 0 10px 0;
      }
    }

    form {
      width: 100%;

      .ant-row,
      .ant-form-item {
        width: 100%;
        margin: 0 0 20px 0;
      }
      .doubleTextFieldWrap {
        max-width: 600px;

        .ant-select-selector {
          border-radius: 0px;
        }

        .ant-select-selection-item span p {
          font-family: "ModellicaMedium2";
          text-transform: none;
          color: #fff9e9;
        }

        .ant-form-item .ant-form-item-control-input-content input {
          color: #ffae00 !important;
          font-family: "ModellicaMedium2";
        }
      }
      & > div {
        @media (max-width: 900px) {
          .selectwrap {
            width: 100%;
            max-width: 600px;
          }
        }
      }
    }

    .statusHeader {
      color: #fff;
      font-family: "MadeOuterMedium";
      font-size: 14px;
      margin: 10px 0 30px 0;
      max-width: 400px;
      @media (max-width: 500px) {
        width: 90%;
      }
    }
    .statusDotWrap {
      .statusDot {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: 1px solid #ffffff99;
        margin: 10px 4px;
      }
      .activeStatusDot {
        background: #e6f59c;
        border-color: #e6f59c;
      }
      @media (min-width: 900px) {
        display: none;
      }
    }
    .starusBoxWrap {
      max-width: 450px;
      .statusBox {
        width: 200px;
        height: 140px;
        border: 1px solid #707070;
        background: #0a0a0a;
        transition: all 0.3s ease;
        position: relative;
        border-radius: 0px;
        cursor: pointer;
        flex-wrap: nowrap;
        img {
          width: 50px;
          opacity: 0.5;
        }
        p {
          color: #ffffff99;
          font-family: "MadeOuterBold";
          margin: 5px 0 7px 0;
          font-size: 16px;
        }
        small {
          color: #ffffff99;
          font-size: 9px;
          text-align: center;
          font-family: "RaitorRegular";
          width: 85%;
        }
        :hover {
          border-color: #e6f59c;
        }
        ::before {
          content: "";
          width: 7px;
          height: 7px;
          border-radius: 100%;
          border: 1px solid #ffffff99;
          right: 10px;
          top: 10px;
          position: absolute;
        }
      }
      .activeStatusBox {
        transform: scale(1.1);
        background: #e6f59c;
        border-color: #707070;

        img {
          opacity: 1;
        }
        small {
          color: #0a0a0a;
        }
        p {
          color: #0a0a0a;
        }
        ::before {
          background: #e6f59c;
          border-color: #e6f59c;
        }
      }
      @media (max-width: 900px) {
        padding: 15px 15px 15px 10px;

        .statusBox {
          min-width: 150px;
        }

        overflow-x: scroll;

        ::-webkit-scrollbar-thumb {
          background: transparent;
        }
        ::-webkit-scrollbar-thumb:active {
          background: transparent;
        }
        ::-webkit-scrollbar-track {
          background: transparent;
        }
        ::-webkit-scrollbar-track:hover {
          background: transparent;
        }
        ::-webkit-scrollbar-track:active {
          background: transparent;
        }
        ::-webkit-scrollbar-corner {
          background: transparent;
        }
      }
    }
    .socialsWrap {
      max-width: 520px;
      span {
        font-size: 14px;
        color: #ffffff99;
        margin: 0 20px 10px 0;
        @media (max-width: 900px) {
          display: none;
        }
      }
    }
    .buttonWrap {
      max-width: 600px;
    }
    .mobileHidenTextFields {
      p {
        color: #ffffff99;
        margin: 10px 0;
        font-size: 14px;
      }
    }

    @media (min-width: 900px) {
      width: 60%;
      .mobileHidenTextFields {
        display: none;
      }
    }
  }
  .date {
    max-width: 600px;
    @media (max-width: 900px) {
      justify-content: flex-start;
    }
  }

  .ant-checkbox-wrapper-checked {
    .ant-checkbox + span .links {
      color: #e2f690 !important;
    }
  }
`;
