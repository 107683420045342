import React, { useEffect, useState } from "react";
import LandingLayout from "../../components/Layout/layout";
import { ThanksStyles } from "./thanks.styles";
import BgImage from "../../assets/img/pngs/thanksImage.png";
import { FlexibleDiv, Section } from "../../components/Box/styles";
import { useNavigate } from "react-router-dom";
import { FiInstagram as Insta } from "react-icons/fi";
import {
  FaTwitter as Twitter,
  FaTiktok as Tiktok,
  FaFacebookF as Facebook,
} from "react-icons/fa";
import Button from "../../components/Button";

const Thanks = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = React.useState({});
  const [active, setActive] = useState("left");

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userdata"));
    if (!userData) {
      navigate("/");
    } else {
      setUserData(userData);
    }
  }, []);

  useEffect(() => {
    const scrollWrap = document.getElementById("thanks");

    scrollWrap.addEventListener("scroll", () => {
      if (
        scrollWrap.offsetWidth + scrollWrap.scrollLeft >=
        scrollWrap.scrollWidth
      ) {
        setActive("right");
      } else {
        setActive("left");
      }
    });
  }, []);

  return (
    <LandingLayout bgImage={BgImage} sectionMarker={"100%"} mobileMenu>
      <ThanksStyles>
        <FlexibleDiv
          justifyContent="space-between"
          className="thanks__mainWrap"
        >
          <Section
            flexDir="column"
            className="thanks__leftSection"
            alignItems="flex-start"
          >
            <h2>
              We have
              <br />
              <span>Your Project</span>
            </h2>

            <FlexibleDiv
              flexDir="column"
              alignItems="flex-start"
              className="thanks__leftSectionSub"
            >
              <p>
                You should get an email confirmation that we have your project
                within the hour. <br /> <br />
                If you do not receive confirmation from our team, it’s more than
                likely we do not have your project or we can’t reach you on the
                email provided.
              </p>
              <br />
              <Button
                onClick={() => navigate("/")}
                width="220px"
                data-glitch={"Start A New Application"}
              >
                Start A New Application
              </Button>
            </FlexibleDiv>
          </Section>

          <FlexibleDiv className="statusDotWrap">
            <FlexibleDiv
              className={
                active === "left" ? "activeStatusDot statusDot" : "statusDot"
              }
            ></FlexibleDiv>
            <FlexibleDiv
              className={
                active === "right" ? "activeStatusDot statusDot" : "statusDot"
              }
            ></FlexibleDiv>
          </FlexibleDiv>

          <Section
            className="thanks__rightSection"
            flexWrap="nowrap"
            id="thanks"
          >
            <FlexibleDiv
              className="rightSection__sub"
              id={active === "left" && "active"}
            >
              <FlexibleDiv className="rigthSection__top">
                <FlexibleDiv className="rigthSection__topInner">
                  <h3>
                    <h3> {userData?.username}</h3> We’ll be in Touch Soon!
                  </h3>
                </FlexibleDiv>
              </FlexibleDiv>
              <FlexibleDiv className="rigthSection__bottom" flexDir="column">
                <p>
                  You don’t need to submit a project more than once! A member of
                  our A&R team will definitely listen to &nbsp;
                  {userData?.project_name} <br /> <br />
                  We have a very thorough vetting process, it can take up to 30
                  days for a member of our team to get back to you. Don’t worry
                  - we believe in communication and will be in touch with
                  updates on your application.
                  <br />
                  <br /> To stay in the know on any announcements, giveaways and
                  more follow us on all our socials.
                </p>
                {/* <FlexibleDiv
                  width="150px"
                  justifyContent="space-between"
                  height="50px"
                  alignItems="flex-end"
                  className="links"
                >
                  <a
                    target="_blank"
                    href="https://twitter.com/wearepoprev"
                    data-glitch={Twitter}
                  >
                    <Twitter color="#E6F59C" />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/wearepoprev/"
                  >
                    <Insta color="#E6F59C" />
                  </a>

                  <a
                    target="_blank"
                    href="https://web.facebook.com/wearepoprev"
                  >
                    <Facebook color="#E6F59C" />
                  </a>
                  <a target="_blank" href="https://www.tiktok.com/@poprev">
                    <Tiktok color="#E6F59C" />
                  </a>
                </FlexibleDiv> */}
              </FlexibleDiv>
            </FlexibleDiv>

            <FlexibleDiv
              className="rightSection__sub mobile"
              id={active === "right" && "active"}
            >
              <FlexibleDiv className="rigthSection__top">
                <FlexibleDiv className="rigthSection__topInner">
                  <h3>
                    Thank You
                    <br />
                    For Applying
                  </h3>
                </FlexibleDiv>
              </FlexibleDiv>
              <FlexibleDiv className="rigthSection__bottom" flexDir="column">
                <p>
                  You should get an email confirmation that we have your project
                  within the hour. <br /> <br />
                  If you do not receive confirmation from our team, it’s more
                  than likely we do not have your project or we can’t reach you
                  on the email provided.
                </p>
                <br />
                <Button
                  onClick={() => navigate("/")}
                  width="220px"
                  data-glitch={"Start A New Application"}
                >
                  Start A New Application
                </Button>
              </FlexibleDiv>
            </FlexibleDiv>
          </Section>
        </FlexibleDiv>
      </ThanksStyles>
    </LandingLayout>
  );
};

export default Thanks;
