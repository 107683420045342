import styled from "styled-components";

export const LayoutWrap = styled.div`
  width: 100%;
  height: max-content;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  background-color: #000;

  .layout__main {
    z-index: 3;
    position: relative;
    margin: 0;
    height: max-content;
    min-height: 100vh;
    width: 100%;
    overflow: hidden auto;
    padding: 70px 5% 50px 10%;

    @media (max-width: 950px) {
      padding: 200px 5% 50px 10%;
    }

    @media (max-width: 800px) {
      padding: 200px 0 50px 7%;
    }
  }
  .boldTxt {
    position: fixed;
    right: 0;
    z-index: 1;
    font-family: "MadeOuterBold";
    font-size: ${({ bgTextSize }) => bgTextSize + "px"};
    transform: ${({ bgTextSize }) =>
      bgTextSize === 130
        ? "rotate(-90deg) translateX(-52vh) translateY(140px)"
        : "rotate(-90deg) translateX(-52vh) translateY(200px)"};

    margin: 0;
    line-height: 0;
    padding: 0;
    color: #ffffff26;

    @media (max-width: 900px) {
      font-size: ${({ bgTextSize }) => `calc(${bgTextSize}px - 30px)`};
      transform: ${({ bgTextSize }) =>
        bgTextSize === 140
          ? "rotate(-90deg) translateX(-52vh) translateY(110px)"
          : "rotate(-90deg) translateX(-52vh) translateY(150px)"};
    }
  }

  .img {
    position: fixed;
    right: 5px;
    width: 70%;
    height: 100vh;
    z-index: 1;
    overflow: hidden;
    clip-path: inset(0% 0% 0% 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: ${({ darkerBg, bgImage }) =>
      darkerBg
        ? `linear-gradient(180deg, #000000d5, #000000d5),  url(${bgImage})`
        : `linear-gradient(180deg, #000000b8, #000000b8),  url(${bgImage})`};

    @media (max-width: 800px) {
      width: 100%;
      background-image: ${({ darkerBg, bgImage }) =>
        darkerBg
          ? `linear-gradient(180deg, #000000e3, #000000),  url(${bgImage})`
          : `linear-gradient(180deg, #000000b8, #000000),  url(${bgImage})`};
    }
  }
`;
