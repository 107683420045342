import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import LandingPage from "./pages/LandingPage/landingPage";
import Thanks from "./pages/Thanks/thanks";
import ScrollToTop from "./components/ScrollManager";
import TellUs from "./pages/TellUs/tellUs";
import LetsTalk from "./pages/LetsTalk/letsTalk";
import MoreDeets from "./pages/MoreDeets/moreDeets";
import "antd/dist/antd.css";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/about-you" element={<TellUs />} />
          <Route path="/project" element={<LetsTalk />} />
          <Route path="/more-details" element={<MoreDeets />} />
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
