import RNB from "../../assets/img/pngs/rnb.png";
import RAP from "../../assets/img/pngs/rap.png";
import Punk from "../../assets/img/pngs/punk.png";
import Afrobeats from "../../assets/img/pngs/afrobeats.png";
import Afrohouse from "../../assets/img/pngs/afro-house.png";
import Dancehall from "../../assets/img/pngs/dancehall.png";
import Alte from "../../assets/img/pngs/alte.png";
import Fusion from "../../assets/img/pngs/fusion.png";
import Fuji from "../../assets/img/pngs/fuji.png";
import Highlife from "../../assets/img/pngs/highlife.png";
import Juju from "../../assets/img/pngs/juju.png";
import Electronic from "../../assets/img/pngs/electronic.png";

export const gender = ["Male", "Female"];

export const status = [
  {
    title: "RAP",
    icon: RAP,
  },
  {
    title: "RNB",
    icon: RNB,
  },
  {
    title: "AFROBEATS",
    icon: Afrobeats,
  },
  {
    title: "AFROHOUSE",
    icon: Afrohouse,
  },
  {
    title: "DANCEHALL",
    icon: Dancehall,
  },
  {
    title: "ROCK/PUNK",
    icon: Punk,
  },
  {
    title: "ALTE VIBES",
    icon: Alte,
  },
  {
    icon: Fusion,
    title: "FUSION",
  },
  {
    title: "HIGHLIFE",
    icon: Highlife,
  },
  {
    title: "JUJU",
    icon: Juju,
  },
  {
    title: "FUJI",
    icon: Fuji,
  },
  {
    title: "ELECTRONIC",
    icon: Electronic,
  },
];
