import React from "react";
import { FooterWrap } from "./styles";
import { FlexibleDiv } from "../../Box/styles";
import { FiInstagram as Insta } from "react-icons/fi";
import {
  FaTwitter as Twitter,
  FaTiktok as Tiktok,
  FaFacebookF as Facebook,
} from "react-icons/fa";

const Footer = () => {
  return (
    <FooterWrap>
      <FlexibleDiv height="100%" flexDir="column">
        <FlexibleDiv width="120px" justifyContent="space-between">
          <a target="_blank" href="https://twitter.com/wearepoprev">
            <Twitter />
          </a>
          <a target="_blank" href="https://www.instagram.com/wearepoprev/">
            <Insta />
          </a>

          <a target="_blank" href="https://web.facebook.com/wearepoprev">
            <Facebook />
          </a>
          <a target="_blank" href="https://www.tiktok.com/@poprev">
            <Tiktok />
          </a>
        </FlexibleDiv>
        <span>(C) 2023 Groove Platforms. All Rights Reserved</span>
      </FlexibleDiv>
    </FooterWrap>
  );
};

export default Footer;
