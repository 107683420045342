import React, { useState, useContext, useEffect } from "react";
import LandingLayout from "../../components/Layout/layout";
import { JoinRevolutionStyles } from "./joinRevolution.styles";
import BgImage from "../../assets/img/pngs/joinTheRevolution.png";
import { FlexibleDiv, Section } from "../../components/Box/styles";
import TextField from "../../components/TextField";
import { Form } from "antd";
import Button from "../../components/Button";
import { CURRENT_USER } from "../../context/types";
import { MainContext } from "../../context/MainContext";
import { status } from "../../utils/dataHelpers/joinRevolution";
import {
  validateEmail,
  validateLastName,
  validateFirstName,
  validateMoniker,
} from "../../utils/fuctionHelpers";
import { useNavigate } from "react-router-dom";

const JoinRevolution = ({ setActivePage }) => {
  const [userStatus, setUserStatus] = useState("");
  const [confirrmUserStatus, setConfirmUserStatus] = useState(false);
  const [form] = Form.useForm();
  const {
    dispatch,
    state: { user },
  } = useContext(MainContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (values) => {
    if (!userStatus) {
      setConfirmUserStatus(true);
      return;
    }
    const payload = { ...values, applicant_type: userStatus };
    setConfirmUserStatus(false);
    await dispatch({ type: CURRENT_USER, payload: { ...user, ...payload } });
    navigate("/about-you", { state: "submission-complete" });
  };

  useEffect(() => {
    localStorage.clear();
    function fillFormDetails() {
      try {
        form.setFieldsValue({
          first_name: user?.first_name || "",
          last_name: user?.last_name || "",
          email: user?.email || "",
          moniker: user?.moniker || "",
        });
        setUserStatus(user?.applicant_type);
      } catch (error) {}
    }
    fillFormDetails();
  }, [user]);

  return (
    <LandingLayout
      bgImage={BgImage}
      bgText="DEETS"
      bgTextSize={140}
      mobileMenu
      darkerBg
    >
      <JoinRevolutionStyles>
        <FlexibleDiv
          justifyContent="space-between"
          className="joinRevolution__mainWrap"
        >
          <Section
            flexDir="column"
            className="joinRevolution__leftSection"
            alignItems="flex-start"
          >
            <h2>
              This is <br />
              <span>PROSPECTS</span>
            </h2>

            <span className="sub__hero__section">
              OUR OFFICIAL SUBMISSION PROCESS
            </span>

            <span>
              For all the Musicians & culture benders that want to submit a
              project to PopRev
            </span>
            <span>Consider this our invitation to you.</span>
          </Section>

          <Section
            flexDir="column"
            className="joinRevolution__rightSection"
            alignItems="flex-start"
          >
            <FlexibleDiv justifyContent="space-between">
              <h3>Your Details</h3>
              <FlexibleDiv
                className="sectionMarker"
                height="5px"
                width="30%"
                justifyContent="flex-start"
                margin="0 -20px 20px 0"
              >
                <FlexibleDiv
                  width="30%"
                  height="4px"
                  margin="-1px 0 0 0"
                ></FlexibleDiv>
              </FlexibleDiv>
            </FlexibleDiv>

            <Form form={form} onFinish={handleSubmit}>
              <FlexibleDiv flexDir="column" alignItems="flex-start">
                <FlexibleDiv
                  className="doubleTextFieldWrap"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <FlexibleDiv width="47%" justifyContent="flex-end">
                    <TextField
                      placeholder="First Name"
                      name="first_name"
                      validator={validateFirstName}
                    />
                  </FlexibleDiv>
                  <FlexibleDiv width="47%" justifyContent="flex-end">
                    <TextField
                      placeholder="Last Name"
                      name="last_name"
                      validator={validateLastName}
                    />
                  </FlexibleDiv>
                </FlexibleDiv>
                <FlexibleDiv width="70%" justifyContent="flex-end">
                  <TextField
                    placeholder="Artist Moniker"
                    name="moniker"
                    validator={validateMoniker}
                  />
                </FlexibleDiv>{" "}
                <FlexibleDiv width="70%" justifyContent="flex-end">
                  <TextField
                    placeholder="Contact Email"
                    name="email"
                    validator={validateEmail}
                  />
                </FlexibleDiv>
              </FlexibleDiv>
              <p className="statusHeader">I’m a/an</p>

              {confirrmUserStatus && !userStatus && (
                <FlexibleDiv justifyContent="flex-start" margin="10px 0">
                  <small className="confirmStatus">
                    We know you’d rather not define yourself, but we need this
                    info!
                  </small>
                </FlexibleDiv>
              )}
              <FlexibleDiv
                justifyContent="space-between"
                flexWrap="nowrap"
                className="starusBoxWrap"
              >
                {status.map((item, idx) => (
                  <FlexibleDiv
                    className={
                      userStatus === item.title
                        ? "statusBox activeStatusBox"
                        : "statusBox"
                    }
                    flexDir="column"
                    onClick={() => setUserStatus(item.title)}
                    style={{
                      margin: idx === 1 && "0 20px",
                    }}
                    key={idx}
                  >
                    <img src={item.icon} alt="" />
                    <p>{item.title}</p>
                    <small>{item.text}</small>
                  </FlexibleDiv>
                ))}
              </FlexibleDiv>

              <FlexibleDiv className="statusDotWrap">
                {status.map((item, idx) => (
                  <FlexibleDiv
                    className={
                      userStatus === item.title
                        ? "activeStatusDot statusDot"
                        : "statusDot"
                    }
                    onClick={() => setUserStatus(item.title)}
                    key={idx}
                  ></FlexibleDiv>
                ))}
              </FlexibleDiv>
              <FlexibleDiv className="buttonWrap" margin="40px 0 0 0">
                <Button
                  htmlType="submit"
                  data-glitch={"Next"}
                  background="#ebf3af"
                  color="#000000"
                >
                  Next
                </Button>
              </FlexibleDiv>
            </Form>
          </Section>
        </FlexibleDiv>
      </JoinRevolutionStyles>
    </LandingLayout>
  );
};

export default JoinRevolution;
