import styled from "styled-components";
import { Form } from "antd";

export const FormItem = styled(Form.Item)`
  width: ${({ width }) => width || "100% !important"};
  margin: ${({ margin }) => margin || "0 0 20px 0"};
  height: ${({ height }) => height || "50px !important"};
  padding: 0;
  background: ${({ background }) => background || "transparent"};
  border: ${({ border }) => border || "1px solid #707070"};
  border-width: ${({ borderwidth }) => borderwidth || "1px"};
  border-radius: ${({ radius, borderwidth }) =>
    borderwidth ? "0" : radius ? radius : "0px"};
  opacity: 1;
  box-sizing: border-box;
  box-shadow: none !important;
  font-size: 14px;
  color: #ffffff;
  transition: all 0.3s ease;
  outline: none;
  overflow: hidden;
  ::placeholder {
    color: #ffffff99;
    font-family: "MadeOuterRegular";
    opacity: 0.5;
    font-size: 14px;
  }

  :hover,
  :active,
  :focus,
  :visited {
    border-color: #fcb31699;
    border-width: ${({ borderwidth }) => borderwidth || "1px"};
  }

  input {
    width: 100%;
    height: 50px;
    background-color: transparent;
    outline: none;
    border: none;
    padding: ${({ padding }) => padding || "0 30px 0 10px"};
    /* padding: ${({ padding, borderwidth }) =>
      borderwidth ? "0" : padding || "0 30px 0 10px"}; */

    ::placeholder {
      color: #ffffff;
      font-family: "MadeOuterLight";
      opacity: 0.5;
      font-size: 14px;
    }
  }

  .ant-form-item-control-input-content {
    height: 50px;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
  }
`;
export const IconWrap = styled.div`
  width: max-content;
  height: max-content;
  max-width: max-content;
  margin: ${({ height }) => (height ? "-65px 10px 0 0" : "-85px 10px 0 0")};
  z-index: 9999;

  svg {
    font-size: 15px;
  }

  .check {
    color: #ace0b6;
  }
  .cancel {
    color: #f54b4b;
  }
  button {
    width: max-content;
    height: max-content;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    box-shadow: none;
    background-color: transparent;
    :hover,
    :active,
    :focus,
    :visited {
      width: max-content;
      height: max-content;
      padding: 0;
      margin: 0;
      outline: none;
      border: none;
      box-shadow: none;
      background-color: transparent;
    }
  }

  .ant-tooltip-inner {
    background-color: #00000099;
  }
  .ant-tooltip {
    font-size: 12px !important;
  }

  .ant-form-item-control-input-content {
    height: 50px;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
  }
`;
