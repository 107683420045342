import styled from "styled-components";

export const TellUsStyles = styled.div`
  width: 100%;
  padding: 30px;
  @media (max-width: 1000px) and (min-width: 900px) {
    padding: 30px 0 0 10px;
  }
  @media (max-width: 800px) {
    padding: 30px 0 0 10px;
  }
  form {
    width: 100%;

    .ant-row {
      width: 100%;
      margin: 0 0 20px 0;
    }
    .doubleTextFieldWrap {
      max-width: 420px;

      .gender__wrapper {
        .ant-form-item {
          width: 100%;
        }
      }

      .ant-select-selector {
        border-radius: 0px;
      }
    }
    & > div {
      @media (max-width: 900px) {
        & > div {
          width: 100%;
        }
        .doubleTextFieldWrap,
        .socialsWrap {
          & > div {
            width: 90%;
          }
        }
      }
    }
  }

  .tellUs__leftSection {
    max-width: 350px;
    h2,
    h2 span {
      font-size: 35px;
      color: #fff;
      font-family: "RaitorRegular";
      margin: 0;

      @media (max-width: 500px) {
        font-size: 40px;
      }
    }

    h2 span {
      color: #ffae00;
      text-transform: uppercase;
    }

    .sub__hero__section {
      color: #d1dbe1;
      text-transform: uppercase;
      font-family: "MadeOuterMedium";
    }

    p {
      color: #fdfeff;
      font-size: 12px;
      line-height: 22px;
      margin: 30px 0 10px 0;
      @media (max-width: 500px) {
        width: 90%;
      }
    }

    .ant-row,
    .ant-form-item {
      width: 100%;
    }
    @media (min-width: 900px) {
      width: 35%;
    }
    @media (max-width: 900px) {
      .countryWeb {
        display: none;
      }
    }
  }

  .tellUs__rightSection {
    h3 {
      font-size: 30px;
      color: #e9f4a6;
      margin-bottom: 30px;
    }

    .statusHeader {
      color: #ffffff;
      font-size: 14px;
      margin: 10px 0 30px 0;
      font-family: "MadeOuterMedium";
      @media (max-width: 500px) {
        width: 90%;
      }
    }
    .statusDotWrap {
      .statusDot {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        border: 1px solid #ffffff99;
        margin: 10px 4px;
      }
      .activeStatusDot {
        background: #ffae00;
        border-color: #ffae00;
      }
      @media (min-width: 900px) {
        display: none;
      }
    }
    .starusBoxWrap {
      max-width: 450px;
      .statusBox {
        width: 150px;
        height: 140px;
        border: 1px solid #707070;
        background: #0a0a0a;
        transition: all 0.3s ease;
        position: relative;
        cursor: pointer;

        img {
          width: 50px;
          opacity: 0.5;
        }
        p {
          color: #ffffff99;
          margin: 0 0 10px 0;
          font-size: 16px;
          font-family: "MadeOuterBold";
        }
        small {
          color: #ffffff99;
          font-size: 11px;
          text-align: center;
          font-family: "RaitorRegular";
          width: 70%;
        }
        :hover {
          border-color: #e9f4a6;
        }
        ::before {
          content: "";
          width: 7px;
          height: 7px;
          border-radius: 100%;
          border: 1px solid #ffffff99;
          right: 10px;
          top: 10px;
          position: absolute;
        }
      }
      .activeStatusBox {
        transform: scale(1.1);
        background: #e9f4a6;
        border-color: #707070;

        img {
          opacity: 1;
        }
        small {
          color: #0a0a0a;
        }
        p {
          color: #0a0a0a;
        }
        ::before {
          background: #e9f4a6;
          border-color: #e9f4a6;
        }
      }
      @media (max-width: 900px) {
        padding: 15px 15px 15px 7px;
        .statusBox {
          min-width: 140px;
        }

        overflow-x: scroll;

        ::-webkit-scrollbar-thumb {
          background: transparent;
        }
        ::-webkit-scrollbar-thumb:active {
          background: transparent;
        }
        ::-webkit-scrollbar-track {
          background: transparent;
        }
        ::-webkit-scrollbar-track:hover {
          background: transparent;
        }
        ::-webkit-scrollbar-track:active {
          background: transparent;
        }
        ::-webkit-scrollbar-corner {
          background: transparent;
        }
      }
    }
    .socialsWrap {
      max-width: 520px;
      align-items: flex-start;
      span {
        font-size: 14px;
        color: #ffffff;
        font-family: "MadeOuterMedium";
        margin: 20px 20px 0 0;
        @media (max-width: 900px) {
          display: none;
        }
      }
    }
    .buttonWrap {
      max-width: 520px;
    }
    .countryMobile {
      max-width: 470px;
    }

    @media (min-width: 900px) {
      width: 60%;
      .countryMobile {
        display: none;
      }
    }
  }
`;
