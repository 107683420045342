import React, { useState, useContext, useEffect } from "react";
import LandingLayout from "../../components/Layout/layout";
import { TellUsStyles } from "./tellUs.styles";
import BgImage from "../../assets/img/pngs/tellUsImage.png";
import { FlexibleDiv, Section } from "../../components/Box/styles";
import TextField from "../../components/TextField";
import { Form, notification } from "antd";
import Button from "../../components/Button";
import {
  AiOutlineArrowRight as Arrow,
  AiOutlineArrowLeft as LeftArrow,
} from "react-icons/ai";
import Select from "../../components/SelectFeild";
import { CURRENT_USER } from "../../context/types";
import { SmileOutlined } from "@ant-design/icons";
import { MainContext } from "../../context/MainContext";
import { countries, gender, status } from "../../utils/dataHelpers/tellUs";
import { IoIosArrowDropdownCircle as ArrowDown } from "react-icons/io";
import {
  validateAge,
  validateInstagram,
  validateFacebook,
  validateTiktok,
} from "../../utils/fuctionHelpers";
import { useLocation, useNavigate } from "react-router-dom";
import Checkbox from "../../components/CheckBox";
import { FaSearch as Search } from "react-icons/fa";

const TellUs = ({ setActivePage }) => {
  const [userStatus, setUserStatus] = useState("");
  const [direction, setDirection] = useState("");
  const [confirrmUserStatus, setConfirmUserStatus] = useState(false);
  const [userGender, setUserGender] = useState("");
  const [selectActive, setSelectActive] = useState(false);
  const [country, setCountry] = useState("");
  const [form] = Form.useForm();
  const {
    dispatch,
    state: { user },
  } = useContext(MainContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
  }, []);

  const handleSubmit = async (values) => {
    if (direction === "forward") {
      if (!userStatus) {
        setConfirmUserStatus(true);
        return;
      }
      if (!values?.tik_tok && !values?.instagram && !values?.facebook) {
        notification.open({
          message: "Error",
          description:
            "You’ll need to provide us with a link to at least one of your social media platforms - No catfishing please 😇",
          icon: <SmileOutlined style={{ color: "red" }} />,
        });
        return;
      }
      const payload = { ...values, signed_status: userStatus };
      await dispatch({ type: CURRENT_USER, payload: { ...user, ...payload } });
      navigate("/project", { state: "submission-complete" });
    } else {
      const payload = { ...values, signed_status: userStatus };

      await dispatch({ type: CURRENT_USER, payload: { ...user, ...payload } });
      navigate("/");
    }
  };

  useEffect(() => {
    function fillFormDetails() {
      try {
        form.setFieldsValue({
          instagram: user?.instagram || "",
          facebook: user?.facebook || "",
          tik_tok: user?.tik_tok || "",
          age: user?.age || "",
          gender: user?.gender || undefined,
          country_of_residence: user?.country_of_residence || undefined,
        });
        setUserStatus(user?.signed_status);
        setUserGender(user?.gender);
      } catch (error) {}
    }
    fillFormDetails();
  }, [user]);

  return (
    <LandingLayout
      bgImage={BgImage}
      bgText="PROFILE"
      sectionMarker={"25%"}
      bgTextSize={130}
    >
      <TellUsStyles>
        <Form form={form} onFinish={handleSubmit}>
          <FlexibleDiv
            justifyContent="space-between"
            className="tellUs__mainWrap"
          >
            <Section
              flexDir="column"
              className="tellUs__leftSection"
              alignItems="flex-start"
            >
              <h2>
                Tell us
                <br />
                <span>About You</span>
              </h2>

              <span className="sub__hero__section">
                THE MIND BEHIND THE MUSIC
              </span>

              <p>
                Tell us a little bit about you, your musical background and more
                importantly how we can contact you
              </p>
              <FlexibleDiv
                className="countryWeb"
                margin="10px 0"
                justifyContent="flex-start"
                width="290px"
                height="60px"
              >
                <Form.Item
                  name="country_of_residence"
                  rules={[
                    {
                      required: direction !== "back" && true,
                      message: "We'd surely like to know where you reside",
                    },
                  ]}
                >
                  <Select
                    placeholder="Country of Residence"
                    showSearch
                    radius="0"
                    suffixIcon={
                      !!selectActive ? (
                        <Search className="search" />
                      ) : (
                        <ArrowDown className="arrow" size={"18"} />
                      )
                    }
                    onChange={() => setSelectActive(true)}
                    onClick={() => setSelectActive(true)}
                    onBlur={() => setSelectActive(false)}
                  >
                    {countries.map((value, index) => (
                      <Select.Option
                        value={value.name}
                        key={index}
                        className="country_item"
                        id={value.name === country && "active_country"}
                      >
                        <FlexibleDiv
                          justifyContent="space-between"
                          onClick={() => setCountry(value.name)}
                        >
                          <span className="country_name">
                            <FlexibleDiv
                              width="20px"
                              height="20px"
                              className="country_flag"
                            >
                              <img
                                src={`https://flagsapi.com/${value.code}/flat/64.png`}
                                alt=""
                              />
                            </FlexibleDiv>

                            {value.name}
                          </span>
                          <span className="dial_code">{value.dial_code}</span>
                        </FlexibleDiv>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </FlexibleDiv>
            </Section>

            <Section
              flexDir="column"
              className="tellUs__rightSection"
              alignItems="flex-start"
            >
              <h3>Hey {user?.first_name}</h3>
              <FlexibleDiv flexDir="column" alignItems="flex-start">
                <FlexibleDiv
                  className="doubleTextFieldWrap"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <FlexibleDiv width="49%" justifyContent="flex-end">
                    <TextField
                      placeholder="Age"
                      name="age"
                      validator={validateAge}
                      direction={direction}
                      type="number"
                    />
                  </FlexibleDiv>
                  <FlexibleDiv width="49%" className="gender__wrapper">
                    <Form.Item
                      name="gender"
                      rules={[
                        {
                          required: direction !== "back" && true,
                          message:
                            "We’re not ones for boxes/labels either.. We do have to get this info though! ",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Gender"
                        suffixIcon={<ArrowDown size={"18"} />}
                        radius="0"
                      >
                        {gender.map((value, index) => (
                          <Select.Option
                            value={value}
                            key={index}
                            onChange={() => setUserGender(value)}
                          >
                            <Checkbox checked={userGender === value} bigText>
                              <p>{value}</p>
                            </Checkbox>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </FlexibleDiv>
                </FlexibleDiv>
              </FlexibleDiv>
              <p className="statusHeader">
                Are you signed to a Label? Or have an existing deal?
              </p>

              {confirrmUserStatus && !userStatus && (
                <FlexibleDiv justifyContent="flex-start" margin="10px 0">
                  <small className="confirmStatus">
                    This helps us with plans for your release. Please choose the
                    most relevant
                  </small>
                </FlexibleDiv>
              )}
              <FlexibleDiv
                justifyContent="space-between"
                flexWrap="nowrap"
                className="starusBoxWrap"
                margin="0 0 20px 0"
              >
                {status.map((item, idx) => (
                  <FlexibleDiv
                    className={
                      userStatus === item.title
                        ? "statusBox activeStatusBox"
                        : "statusBox"
                    }
                    flexDir="column"
                    onClick={() => setUserStatus(item.title)}
                    key={idx}
                    style={{ margin: idx === 1 && "0 20px" }}
                  >
                    <img src={item.icon} alt="" />
                    <p>{item.title}</p>
                    <small>{item.text}</small>
                  </FlexibleDiv>
                ))}
              </FlexibleDiv>

              <FlexibleDiv className="statusDotWrap">
                {status.map((item, idx) => (
                  <FlexibleDiv
                    className={
                      userStatus === item.title
                        ? "activeStatusDot statusDot"
                        : "statusDot"
                    }
                    key={idx}
                    onClick={() => setUserStatus(item.title)}
                  ></FlexibleDiv>
                ))}
              </FlexibleDiv>

              <p className="statusHeader">
                If we’re a little nosey… Where can we $talk you?
              </p>
              <FlexibleDiv
                justifyContent="space-between"
                className="socialsWrap"
              >
                <span>Instagram</span>
                <FlexibleDiv width="77%" justifyContent="flex-end">
                  <TextField
                    placeholder="https://...instagram…"
                    name="instagram"
                    borderwidth="0 0 1px 0"
                    radius="0"
                    height="35px"
                    padding="0"
                    validator={validateInstagram}
                    direction={direction}
                  />
                </FlexibleDiv>
              </FlexibleDiv>
              <FlexibleDiv
                justifyContent="space-between"
                className="socialsWrap"
              >
                <span>TikTok</span>
                <FlexibleDiv width="77%" justifyContent="flex-end">
                  <TextField
                    placeholder="https://...tiktok…"
                    name="tik_tok"
                    borderwidth="0 0 1px 0"
                    radius="0"
                    height="35px"
                    padding="0"
                    validator={validateTiktok}
                    direction={direction}
                  />
                </FlexibleDiv>
              </FlexibleDiv>
              <FlexibleDiv
                justifyContent="space-between"
                className="socialsWrap"
              >
                <span>Facebook</span>
                <FlexibleDiv width="77%" justifyContent="flex-end">
                  <TextField
                    placeholder="https://...facebook…"
                    name="facebook"
                    borderwidth="0 0 1px 0"
                    radius="0"
                    height="35px"
                    padding="0"
                    validator={validateFacebook}
                    direction={direction}
                  />
                </FlexibleDiv>
              </FlexibleDiv>
              <FlexibleDiv
                className="countryMobile"
                margin="10px 0"
                justifyContent="flex-start"
                width="90%"
              >
                <Form.Item
                  name="country_of_residence"
                  rules={[
                    {
                      required: direction !== "back" && true,
                      message: "We'd surely like to know where you reside",
                    },
                  ]}
                >
                  <Select
                    placeholder="Country of Residence"
                    showSearch
                    suffixIcon={
                      !!selectActive ? (
                        <Search className="search" />
                      ) : (
                        <ArrowDown className="arrow" />
                      )
                    }
                    onChange={() => setSelectActive(true)}
                    onClick={() => setSelectActive(true)}
                    onBlur={() => setSelectActive(false)}
                  >
                    {countries.map((value, index) => (
                      <Select.Option
                        value={value.name}
                        key={index}
                        className="country_item"
                        id={value.name === country && "active_country"}
                      >
                        <FlexibleDiv
                          justifyContent="space-between"
                          onClick={() => setCountry(value.name)}
                        >
                          <span className="country_name">
                            <FlexibleDiv
                              width="20px"
                              height="20px"
                              className="country_flag"
                            >
                              <img
                                src={`https://flagsapi.com/${value.code}/flat/64.png`}
                                alt=""
                              />
                            </FlexibleDiv>

                            {value.name}
                          </span>
                          <span className="dial_code">{value.dial_code}</span>
                        </FlexibleDiv>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </FlexibleDiv>

              <FlexibleDiv
                className="buttonWrap"
                margin="40px 0 0 0"
                justifyContent="space-between"
                width="90%"
              >
                <Button
                  htmlType={"submit"}
                  onClick={() => setDirection("back")}
                  data-glitch={"Back"}
                  background="#D1DBE1"
                  color="#000000"
                  radius="0px"
                  backButton
                >
                  Back
                </Button>
                <Button
                  htmlType="submit"
                  onClick={() => setDirection("forward")}
                  data-glitch={"Next"}
                  background="#D1DBE1"
                  color="#000000"
                  radius="0px"
                >
                  Next
                </Button>
              </FlexibleDiv>
            </Section>
          </FlexibleDiv>
        </Form>
      </TellUsStyles>
    </LandingLayout>
  );
};

export default TellUs;
