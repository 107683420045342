import styled from "styled-components";
import { Checkbox } from "antd";

export default styled(Checkbox)`
  margin-top: ${({ bigText }) => (bigText ? "0" : "10px")};
  width: max-content;
  .ant-checkbox {
    height: max-content;
    margin: 0;
    padding: 0;
    line-height: 0;
    margin-right: ${({ bigText }) => (bigText ? "3px" : "10px")};

    input {
      height: 0;
    }
  }

  .ant-checkbox-inner {
    background: transparent !important;
    border-color: ${({ boxColor, bigText }) =>
      bigText ? "#707070" : boxColor || "#fff"};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after,
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${({ boxColor, bigText }) =>
      bigText ? "#FFAE00" : boxColor || "#fff"};
  }

  .ant-checkbox-inner::after,
  .ant-checkbox-input:checked,
  .ant-checkbox-checked::after,
  .ant-checkbox-input:checked + .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${({ onCheckColor }) =>
      onCheckColor ? onCheckColor : "#E6F59C"};
  }

  p {
    font-size: ${({ bigText }) => (bigText ? "12px" : "12px")};
    margin-bottom: ${({ bigText }) => bigText && "0"};
    color: #fff;
    font-family: "ModellicaMedium";
  }
  .links {
    font-size: 12px;
    opacity: 0.7;
    color: #fff;
    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  :hover {
    .ant-checkbox-inner,
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${({ boxColor, bigText }) =>
        bigText ? "#fff" : boxColor || "#fff"};
    }
  }
`;
