import React, { useEffect } from "react";
import { LayoutWrap } from "./layout.styles";
import SideBar from "./SideBar";
import Footer from "./Footer";
import MobileHeader from "./MobileHeader";
import gsap from "gsap";

// general layout for landing page
const LandingLayout = ({
  bgImage,
  smallerText,
  bgText,
  darkerBg,
  children,
  sectionMarker,
  bgTextSize,
  mobileMenu,
}) => {
  useEffect(() => {
    const heroAnimation = gsap.timeline({ delay: 0 });
    heroAnimation.to("#a-hero_image", {
      duration: 2,
      ease: "expo.out",
      clipPath: "inset(0% 0% 0% 0%)",
    });
  }, []);

  return (
    <LayoutWrap
      bgImage={bgImage}
      darkerBg={darkerBg}
      smallerText={smallerText}
      id="layout_section"
      bgTextSize={bgTextSize}
    >
      <div className="img" id="a-hero_image"></div>

      <MobileHeader mobileMenu={mobileMenu} />
      <SideBar sectionMarker={sectionMarker} />
      <h1 className="boldTxt">{bgText}</h1>

      <main className="layout__main">{children} </main>
      <Footer />
    </LayoutWrap>
  );
};

export default LandingLayout;
