import React, { useState, useContext, useEffect } from "react";
import LandingLayout from "../../components/Layout/layout";
import { LetsTalkStyles } from "./letsTalk.styles";
import BgImage from "../../assets/img/pngs/letsTalkImage.png";
import { FlexibleDiv, Section } from "../../components/Box/styles";
import TextField from "../../components/TextField";
import Checkbox from "../../components/CheckBox";
import { Form } from "antd";
import Button from "../../components/Button";
import {
  AiOutlineArrowRight as Arrow,
  AiOutlineArrowLeft as LeftArrow,
} from "react-icons/ai";
import Select from "../../components/SelectFeild";
import { CURRENT_USER } from "../../context/types";
import { MainContext } from "../../context/MainContext";
import {
  status,
  aboutUs,
  distributionDeal,
  devPhase,
  whyPoprev,
  checkListData,
} from "../../utils/dataHelpers/letsTalk";
import DatePicker from "../../components/DatePicker";
import moment from "moment";
import { IoIosArrowDropdownCircle as ArrowDown } from "react-icons/io";
import {
  validateProjectLink,
  validateProjectName,
} from "../../utils/fuctionHelpers";
import { useLocation, useNavigate } from "react-router-dom";

const LetsTalk = ({ setActivePage }) => {
  const [userStatus, setUserStatus] = useState("");
  const [applicantNeed, setApplicantNeed] = useState([]);
  const [confirrmUserStatus, setConfirmUserStatus] = useState(false);
  const [confirrmUserDate, setConfirmDate] = useState(false);
  const [direction, setDirection] = useState("");
  const [dropdownState, setDropdownState] = useState({});
  const [date, setDate] = useState({});
  const [form] = Form.useForm();
  const {
    dispatch,
    state: { user },
  } = useContext(MainContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
  }, []);

  const handleSubmit = async (values) => {
    const thirtyDaysTime = moment().add(30, "days").format("YYYY-MM-DD");

    if (direction === "forward") {
      if (!userStatus) {
        setConfirmUserStatus(true);
        return;
      }

      if (!date?.day || !date?.month || !date?.year) {
        setConfirmDate(
          "We will need to know your project release plans & Dates"
        );
        return;
      }
      if (
        moment(`${date.year}-${date?.month}-${date.day}`).isBefore(
          thirtyDaysTime
        )
      ) {
        setConfirmDate(
          "We need a release date of no less than 30 days from today"
        );
        return;
      }
      const payload = {
        ...values,
        project_type: userStatus,
        applicant_need: applicantNeed,
        release_date: date,
      };
      await dispatch({ type: CURRENT_USER, payload: { ...user, ...payload } });
      navigate("/more-details", { state: "submission-complete" });
    } else {
      const payload = {
        ...values,
        project_type: userStatus,
        applicant_need: applicantNeed,
        release_date: date,
      };

      await dispatch({ type: CURRENT_USER, payload: { ...user, ...payload } });
      navigate("/about-you", { state: "submission-complete" });
    }
  };

  const handleSetApplicantNeed = (e, value) => {
    if (!!e.target.checked) {
      setApplicantNeed([...applicantNeed, value]);
    } else {
      applicantNeed.splice(applicantNeed.indexOf(value), 1);
      setApplicantNeed([...applicantNeed]);
    }
  };

  useEffect(() => {
    function fillFormDetails() {
      try {
        form.setFieldsValue({
          project_dev_phase: user?.project_dev_phase || undefined,
          project_name: user?.project_name || "",
          private_project_link: user?.private_project_link || "",
          distribution_deal: user?.distribution_deal || undefined,
          how_pop_rev: user?.how_pop_rev || undefined,
          why_pop_rev: user?.why_pop_rev || undefined,
        });
        setUserStatus(user?.project_type);
        setApplicantNeed(user?.applicant_need || []);
        setDate(user?.release_date);
        setDropdownState({
          project_dev_phase: user?.project_dev_phase || undefined,
          distribution_deal: user?.distribution_deal || undefined,
          how_pop_rev: user?.how_pop_rev || undefined,
          why_pop_rev: user?.why_pop_rev || undefined,
        });
      } catch (error) {}
    }
    fillFormDetails();
  }, [user]);

  const handleDateChange = (newDate, dateType) => {
    console.log(newDate, "skm x");
    let compileDate = date;
    const thirtyDaysTime = moment().add(30, "days").format("YYYY-MM-DD");
    if (dateType === "day") {
      setDate({ ...date, day: newDate.format("DD"), mainDate: newDate });
      compileDate = { ...compileDate, day: newDate.format("DD") };
    }
    if (dateType === "month") {
      setDate({ ...date, month: newDate.format("MM") });
      compileDate = { ...compileDate, month: newDate.format("MM") };
    }
    if (dateType === "year") {
      setDate({ ...date, year: newDate.format("YYYY") });
      compileDate = { ...compileDate, year: newDate.format("YYYY") };
    }

    if (
      moment(
        `${compileDate.year}-${compileDate?.month}-${compileDate.day}`
      ).isBefore(thirtyDaysTime)
    ) {
      setConfirmDate(
        "We need a release date of no less than 30 days from today"
      );
    } else {
      setConfirmDate(false);
    }
  };
  const dateFormat = "DD";
  const monthFormat = "MM";
  const yearFormat = "YYYY";

  function disabledYear(current) {
    return current.year() < 2022;
  }

  return (
    <LandingLayout
      bgImage={BgImage}
      bgText="PROJECT"
      darkerBg
      sectionMarker={"50%"}
      bgTextSize={110}
    >
      <LetsTalkStyles>
        <FlexibleDiv
          justifyContent="space-between"
          className="letsTalk__mainWrap"
        >
          <Section
            flexDir="column"
            className="letsTalk__leftSection"
            alignItems="flex-start"
          >
            <h2>
              Tell us about
              <br />
              <span>The Project</span>
            </h2>
            <span className="sub__hero__section">
              LETS TALK RELEASE DETAILS
            </span>

            <p className="mobileIntoText">
              WELCOME TO OUR OFFICIAL SUBMISSION PROCESS If you’d like to be a
              PopRev draft pick and have your project featured on PopRev Roster
              for funding,
            </p>
            <FlexibleDiv
              flexDir="column"
              alignItems="flex-start"
              className="letsTalk__leftSectionSub"
            >
              <p>When are you looking to launch the project in question.</p>

              {confirrmUserDate && (
                <FlexibleDiv justifyContent="flex-start">
                  <small className="confirmStatus">{confirrmUserDate}</small>
                  <br />
                </FlexibleDiv>
              )}

              <FlexibleDiv className="date" justifyContent="space-between">
                <DatePicker
                  suffixIcon={<ArrowDown size={"18"} />}
                  picker="day"
                  radius="0px"
                  placeholder="Date"
                  width="30%"
                  format={dateFormat}
                  onChange={(e) => {
                    handleDateChange(e, "day");
                  }}
                  value={!!date?.day && moment(date?.mainDate, dateFormat)}
                />

                <DatePicker
                  suffixIcon={<ArrowDown size={"18"} />}
                  picker="month"
                  placeholder="Month"
                  radius="0px"
                  width="30%"
                  format={monthFormat}
                  onChange={(e) => {
                    handleDateChange(e, "month");
                  }}
                  value={!!date?.month && moment(date?.month, monthFormat)}
                  minDate={0}
                />
                <DatePicker
                  suffixIcon={<ArrowDown size={"18"} />}
                  picker="year"
                  placeholder="Year"
                  radius="0px"
                  width="30%"
                  format={yearFormat}
                  onChange={(e) => {
                    handleDateChange(e, "year");
                  }}
                  value={!!date?.year && moment(date?.year, yearFormat)}
                  disabledDate={disabledYear}
                />
              </FlexibleDiv>

              <p id="popRev__help">
                Minus putting out my project I would like PopRev to be involved
                withneed PopRev to help with
              </p>

              <FlexibleDiv>
                {checkListData.map((item, index) => (
                  <FlexibleDiv justifyContent="flex-start" key={index}>
                    <Checkbox
                      onCheckColor={"#e2f690"}
                      checked={applicantNeed?.includes(item)}
                      onChange={(e) => {
                        handleSetApplicantNeed(e, item);
                      }}
                    >
                      <span className="links">{item}</span>
                    </Checkbox>
                  </FlexibleDiv>
                ))}
              </FlexibleDiv>
            </FlexibleDiv>
          </Section>

          <Section
            flexDir="column"
            className="letsTalk__rightSection"
            alignItems="flex-start"
          >
            <h3>{user?.moniker}</h3>

            <Form form={form} onFinish={handleSubmit}>
              <p className="statusHeader">
                What type of Project would you like to put out with PopRev
              </p>

              {confirrmUserStatus && !userStatus && (
                <FlexibleDiv justifyContent="flex-start" margin="10px 0">
                  <small className="confirmStatus">
                    Please pick one of two options - we know it can be tricky!
                  </small>
                </FlexibleDiv>
              )}
              <FlexibleDiv
                justifyContent="space-between"
                flexWrap="nowrap"
                className="starusBoxWrap"
                margin="0 0 20px 0"
              >
                {status.map((item, idx) => (
                  <FlexibleDiv
                    className={
                      userStatus === item.title
                        ? "statusBox activeStatusBox"
                        : "statusBox"
                    }
                    flexDir="column"
                    onClick={() => setUserStatus(item.title)}
                    style={{ margin: idx === 1 && "0 20px" }}
                  >
                    <img src={item.icon} alt="" />
                    <p>{item.title}</p>
                    <small>{item.text}</small>
                  </FlexibleDiv>
                ))}
              </FlexibleDiv>

              <FlexibleDiv className="statusDotWrap">
                {status.map((item, idx) => (
                  <FlexibleDiv
                    className={
                      userStatus === item.title
                        ? "activeStatusDot statusDot"
                        : "statusDot"
                    }
                    onClick={() => setUserStatus(item.title)}
                  ></FlexibleDiv>
                ))}
              </FlexibleDiv>

              <FlexibleDiv
                className="doubleTextFieldWrap"
                justifyContent="space-between"
                width="92%"
                alignItems="center"
                margin="30px 0 0 0"
              >
                <FlexibleDiv width="47%" className="selectwrap">
                  <Form.Item
                    name="project_dev_phase"
                    rules={[
                      {
                        required: direction !== "back" && true,
                        message:
                          "We can’t help fund + push a project if we don’t know where you’re at  👀",
                      },
                    ]}
                  >
                    <Select
                      placeholder="PROJECT PHASE"
                      radius="0px"
                      suffixIcon={<ArrowDown size={"18"} />}
                    >
                      {devPhase.map((value, index) => (
                        <Select.Option
                          value={value}
                          key={index}
                          onChange={() =>
                            setDropdownState({
                              ...dropdownState,
                              project_dev_phase: value,
                            })
                          }
                        >
                          <Checkbox
                            onCheckColor={"#E6F59C"}
                            checked={dropdownState?.project_dev_phase === value}
                            bigText
                          >
                            <p>{value}</p>
                          </Checkbox>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </FlexibleDiv>
                <FlexibleDiv width="47%" className="selectwrap">
                  <Form.Item
                    name="distribution_deal"
                    rules={[
                      {
                        required: direction !== "back" && true,
                        message:
                          "We need to know how far we can push your work ☺️",
                      },
                    ]}
                  >
                    <Select
                      placeholder="DISTRIBUTION?"
                      radius="0px"
                      suffixIcon={<ArrowDown size={"18"} />}
                    >
                      {distributionDeal.map((value, index) => (
                        <Select.Option
                          value={value}
                          key={index}
                          onChange={() =>
                            setDropdownState({
                              ...dropdownState,
                              distribution_deal: value,
                            })
                          }
                        >
                          <Checkbox
                            onCheckColor={"#E6F59C"}
                            checked={dropdownState?.distribution_deal === value}
                            bigText
                          >
                            <p>{value}</p>
                          </Checkbox>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </FlexibleDiv>
              </FlexibleDiv>

              <FlexibleDiv
                className="doubleTextFieldWrap"
                width="92%"
                margin="-20px 0 0 0"
                justifyContent="flex-end"
              >
                <TextField
                  placeholder="What is the name of the project you want to release?"
                  borderwidth="0 0 1px 0"
                  radius="0"
                  height="40px"
                  padding="0"
                  direction={direction}
                  validator={validateProjectName}
                  name="project_name"
                />
              </FlexibleDiv>

              <FlexibleDiv
                className="doubleTextFieldWrap"
                width="92%"
                justifyContent="flex-end"
              >
                <TextField
                  placeholder="Please provide a private link where we can listen to the project?"
                  borderwidth="0 0 1px 0"
                  radius="0"
                  height="40px"
                  padding="0"
                  direction={direction}
                  validator={validateProjectLink}
                  name="private_project_link"
                />
              </FlexibleDiv>

              <FlexibleDiv
                className="doubleTextFieldWrap"
                width="92%"
                justifyContent="space-between"
                margin="20px 0 0 0"
                alignItems="flex-start"
              >
                <FlexibleDiv width="47%" className="selectwrap">
                  <Form.Item
                    name="why_pop_rev"
                    rules={[
                      {
                        required: direction !== "back" && true,
                        message: "Why do you want to become a Draft Pick?",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Why Poprev?"
                      radius="0px"
                      suffixIcon={<ArrowDown size={"18"} />}
                    >
                      {whyPoprev.map((value, index) => (
                        <Select.Option
                          value={value}
                          key={index}
                          onChange={() =>
                            setDropdownState({
                              ...dropdownState,
                              why_pop_rev: value,
                            })
                          }
                        >
                          <Checkbox
                            onCheckColor={"#E6F59C"}
                            checked={dropdownState?.why_pop_rev === value}
                            bigText
                          >
                            <p>{value}</p>
                          </Checkbox>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </FlexibleDiv>
                <FlexibleDiv width="47%" className="selectwrap">
                  <Form.Item
                    name="how_pop_rev"
                    rules={[
                      {
                        required: direction !== "back" && true,
                        message: "We would just like to know… For a friend 😂",
                      },
                    ]}
                  >
                    <Select
                      placeholder="How did you hear about us?"
                      radius="0px"
                      suffixIcon={<ArrowDown size={"18"} />}
                    >
                      {aboutUs.map((value, index) => (
                        <Select.Option
                          value={value}
                          key={index}
                          onChange={() =>
                            setDropdownState({
                              ...dropdownState,
                              how_pop_rev: value,
                            })
                          }
                        >
                          <Checkbox
                            onCheckColor={"#E6F59C"}
                            checked={dropdownState?.how_pop_rev === value}
                            bigText
                          >
                            <p>{value}</p>
                          </Checkbox>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </FlexibleDiv>
              </FlexibleDiv>

              <FlexibleDiv
                flexDir="column"
                alignItems="flex-start"
                className="mobileHidenTextFields"
                width="92%"
              >
                <p>When do you want to release the project.</p>
                <FlexibleDiv className="date">
                  <DatePicker
                    suffixIcon={<ArrowDown />}
                    picker="day"
                    placeholder="Date"
                    width="30%"
                    format={dateFormat}
                    onChange={(e) => {
                      handleDateChange(e, "day");
                    }}
                    value={!!date?.day && moment(date?.day, dateFormat)}
                  />
                  <DatePicker
                    suffixIcon={<ArrowDown />}
                    picker="month"
                    placeholder="Month"
                    width="30%"
                    format={monthFormat}
                    onChange={(e) => {
                      handleDateChange(e, "month");
                    }}
                    value={!!date?.month && moment(date?.month, monthFormat)}
                    minDate={0}
                  />
                  <DatePicker
                    suffixIcon={<ArrowDown />}
                    picker="year"
                    placeholder="Year"
                    width="30%"
                    format={yearFormat}
                    onChange={(e) => {
                      handleDateChange(e, "year");
                    }}
                    value={!!date?.year && moment(date?.year, yearFormat)}
                  />
                </FlexibleDiv>

                {confirrmUserDate &&
                  (!date?.day || !date?.month || !date?.year) && (
                    <FlexibleDiv justifyContent="flex-start">
                      <small className="confirmStatus">
                        {confirrmUserDate}
                      </small>
                    </FlexibleDiv>
                  )}
                {confirrmUserDate &&
                  !!date?.day &&
                  !!date?.month &&
                  !!date?.year && (
                    <FlexibleDiv justifyContent="flex-start">
                      <small className="confirmStatus">
                        {confirrmUserDate}
                      </small>
                    </FlexibleDiv>
                  )}

                <p>I will need PopRev to help with</p>
                {checkListData.map((item, index) => (
                  <FlexibleDiv justifyContent="flex-start">
                    <Checkbox
                      onCheckColor={"#e2f690"}
                      checked={applicantNeed?.includes(item)}
                      onChange={(e) => {
                        handleSetApplicantNeed(e, item);
                      }}
                      key={index}
                    >
                      <span className="links">{item}</span>
                    </Checkbox>
                  </FlexibleDiv>
                ))}
              </FlexibleDiv>
              <FlexibleDiv
                className="buttonWrap"
                margin="40px 0 0 0"
                justifyContent="space-between"
                width="90%"
              >
                <Button
                  htmlType={"submit"}
                  background="#3E3E3E"
                  onClick={() => setDirection("back")}
                  data-glitch={"Back"}
                  radius="0px"
                  border={"1px solid transparent"}
                  backButton
                >
                  <LeftArrow /> Back
                </Button>
                <Button
                  htmlType="submit"
                  background="#3E3E3E"
                  onClick={() => setDirection("forward")}
                  border={"1px solid transparent"}
                  data-glitch={"Next"}
                  radius="0px"
                >
                  Next <Arrow />
                </Button>
              </FlexibleDiv>
            </Form>
          </Section>
        </FlexibleDiv>
      </LetsTalkStyles>
    </LandingLayout>
  );
};

export default LetsTalk;
