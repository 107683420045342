import styled from "styled-components";

const Button = styled.button`
  width: ${({ width }) => width || "100px"};
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height || "40px"};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border || "1px solid #707070"};
  background: ${({ background }) => background || "transparent"};
  border-radius: ${({ radius }) => radius || "2px"};
  box-shadow: ${({ boxShadow }) => boxShadow || "none"};
  opacity: ${({ opacity }) => opacity || "1"};
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  color: ${({ color }) => color || "#FFFFFF"} !important;
  font-weight: 600;
  font-family: "MadeOuterBold";
  font-size: ${({ fontSize }) => fontSize || "14px"};
  @media (max-width: 350px) {
    height: ${({ height }) => height === "68px" && "50px"};
  }
  :focus,
  :active {
    background: transparent;
    border-color: ${({ background }) => background || "#ffffff99"};
  }
  :hover {
    background: transparent;
    color: ${({ background }) => background || "#FFFFFF"} !important;
    border-color: ${({ background }) => background || "#fcb31699"};

    &[data-glitch] {
      position: relative;
      &:before,
      &:after {
        content: attr(data-glitch);
        position: absolute;
        left: ${({ backButton, SubmitButton }) =>
          SubmitButton ? "20%" : !backButton && "30%"};
        right: ${({ backButton }) => backButton && "29%"};
        opacity: 0.8;
      }
      &:after {
        color: #f0f;
        z-index: -2;
        animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
          reverse both;
      }
      &:before {
        color: #0ff;
        z-index: -1;
        animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
          both;
      }
    }
    @keyframes glitch {
      0% {
        transform: translate(0);
      }
      20% {
        transform: translate(-2px, 2px);
      }
      40% {
        transform: translate(-2px, -2px);
      }
      60% {
        transform: translate(2px, 2px);
      }
      80% {
        transform: translate(2px, -2px);
      }
      to {
        transform: translate(0);
      }
    }
  }
  svg {
    display: none;
  }
`;
export default Button;
