import styled from "styled-components";
import { DatePicker } from "antd";

export default styled(DatePicker)`
  width: ${({ width }) => width || "100% "};
  margin: ${({ margin }) => margin || "0 0 20px 0"};
  height: ${({ height }) => height || "50px"};
  padding: ${({ padding }) => padding || "0 10px"};
  background: ${({ background }) => background || "transparent"};
  border: ${({ border }) => border || "1px solid #707070"};
  border-width: ${({ borderwidth }) => borderwidth || "1px"};
  border-radius: ${({ radius }) => radius || "6px"};
  opacity: 1;
  box-sizing: border-box;
  box-shadow: none !important;
  font-size: 14px;
  transition: all 0.3s ease;
  outline: none;
  ::placeholder {
    color: #ffffff99;
    opacity: 0.5;
    font-size: 14px;
  }
  :hover,
  :active,
  :focus,
  :visited {
    border-color: #fcb31699;
    border-width: ${({ borderwidth }) => borderwidth || "1px"};
  }
  .ant-picker-clear {
    background: transparent;
    transform: scalse(1);
  }
  svg {
    color: #fff;
  }
  input {
    color: #ffffff;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;
