import styled from "styled-components";

export const FooterWrap = styled.div`
  width: 100%;
  height: 80px;
  padding: 0 7% 0 20px;
  background: #0f0f0f;
  position: absolute;
  z-index: 3;

  a {
    color: #fff;
  }
  span {
    color: #ffffff99;
    font-size: 10px;
    margin: 5px 0 0 0;
  }
  & > div {
    @media (min-width: 800px) {
      align-items: flex-end;
    }
  }
`;
